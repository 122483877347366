
const faq_items = [
  {
    question: "How does the SparkSIP process work?",
    id: "process",
    answer:[
      "SparkSIP requests organizations to sponsor internship projects.",
      "Students apply for these projects and get matched for an interview. On mutual acceptance, the internships start in the summer."
    ],
    component_id: "TIMELINE"
  },
  {
    question: "What kind of projects are good?",
    id: "projects-type",
    answer:[
      "You are looking at high school kids going into Sophomore, Junior, and Senior years.",
      "Students come from diverse backgrounds with diverse interests in STEM, Business, Marketing, Climate Change, Social Equity, Art, Writing, etc.",
      "Business Students: code-calls, prospecting, research, webinars, videography, social marketing, ...",
      "Tech Students: Testing, coding, prototyping, design, user experience, games, etc.",
      "Ex: robot for roof cleaning (yes we tried that!)",
      "Ex: marketing data analysis for sports goods",
    ]
  },
  {
    question: "What are some past projects?",
    id: "past-projects",
    answer:[
      "Marketing and Product Research for sports goods business.",
      "Electronics Engineer (Neural Interface Technologies).",
      "Design and build robot for roof cleaning (yes we tried that!)",
      "Develop website for a wayfinding software company.",
      "Data Science in Genomics.",
      "Art and creative writing for a children's book",
      "Analyze and develop design for sensors in a fabric for wearables.",
      "Statical Analysis to Identify New Markets.",
      "Technical Sales and Support for Indoor Mapping software.",
      "Design and develop SparkSIP Internship Matcher.",
      "Crawler for Information Extraction.",
      "ML Bots for Retail Industry.",
      "Nanoscale Device and Materials Modeling.",
    ],
    link_id: "/projects",
    link_name: "View Projects"
  },
  {
    question: "Does the business need to pay for the internship?",
    id: "project-pay",
    answer:[
      "We expect that business sponsors to invest time in coaching and helping students.",
      "In terms of monetary compensation, we do not require any payment; we recommend some form of stipend not exceeding $500 max per intern.",
      "We do strongly recommend that the sponsor invest time (or delegate) to work closely with the students to orient them and provide support for them to complete the work."
    ]
  },
  {
    question: "Can I refer my friend's business to sponsor projects?",
    id: "referral",
    answer:[
      "Yes! We would love it. We have grown the sponsored projects by referrals and word of mouth. Thanks for your contributions to build a stronger community.",
    ],
    link_id: "/refer",
    link_name: "Refer a Business"
  },
  {
    question: "Can you share information from the past years?",
    id: "info-from-past",
    answer:[
      "Glad you asked. You can find additional information from the past year at: https://www.linkedin.com/pulse/spark-sip-interns-sparkle-murali-krishnan/",
      "Hands-on and thoughtful work during the summer nurtures these students to grow their talent and contribution to the society."
    ]
  },
  {
    question: "Why are you folks at SparkSIP doing this?",
    id: "purpose",
    answer:[
      "We came from places where economic opportunities and meaningful work was limited. We learnt from many mentors in apprentices and coaching sessions. We feel that similar opportunities will benefit students and organizations.",
    ]
  },
  {
    question: "Where can I learn more?",
    id: "learn-more",
    answer:[
      "Please submit your request in the contact us form and we will follow up."
    ],
    link_id: "/contact",
    link_name: "Contact Us"
  }
];

export default faq_items;
