import React from "react";

// ToDo: enable online submissions in the future
const SubmitApplications = () => (
  <>
  <p> Students will submit the application package.
  Please make sure that the package is complete (resumes match the essays,
    transcript is legible etc).
  <br/>
  Spark SIP volunteers will do their best to try and work with the student
   to make their application better and stronger.
  But sometimes if the student does not respond in a timely manner,
  incomplete applications will be rejected,
  and the application fee will be forfeited.
  </p><p>
  Spark SIP will assess applicant suitability to internship requests
  by the end of April. SPARK SIP Team will review applications for
  student eligibility and position prerequisites stated
  on the position descriptions.
  SPARKSIP team will also be contacting students if there are any with questions
   on their applications. In some cases, the team will also notify students
   if we place their application into positions other
   than the ones they have requested if a better fit is found.
  </p><p><i>
Note: Students should check email and phone messages frequently during this time
 as the SPARK SIP team may contact them with application eligibility,
 opportunities for additional application placements,
 and questions on their applications.
In most cases if there are any questions about an application,
  the student may only have a day or two to reply with the information
  before their application will be taken out of consideration.
  </i>
  </p><p>
Spark SIP will select the top 3-5 applicants and
  submit their applications packages to the internship provider.
  This number may vary based on the number of positions with the provider.
  </p>
  </>
);

const StudentSubmitApp = {
  id: "submit_app",
  name: "Apply",
  status: "not-ready",

  onNow: false,
  title: "Submit Application",

  imgsrc: "/images/desk_laptop1.svg",
  imgalt:"Submit Application",
  imgcols:4,
  imgheight:"300px",

  buttonText: "Submit Candidates",
  buttonColor: "info",
  // turn on buttonPath when we are ready
  // buttonPath:"/business/review",

  messageBody: (<SubmitApplications />)
};

export default StudentSubmitApp;
