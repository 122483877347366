/*
  student_profile.js - defines the "Student Profile"
*/
import GoogleSheet from './../../providers/googlesheet';
import { DbObject } from "./../../util/db";
const StudentProfileDbObject = new DbObject( "student_profile", false);

// ------ ------ ------ ------ ------ ------ ------ ------ ------ ------
//		Field definitions for UserProfile v1 Form
// ------ ------ ------ ------ ------ ------ ------ ------ ------ ------
/* FOR FUTURE GENERALIZATION
const USER_PROFILE_FORM_DATA = {
  name: { id: "name", label: "Name", type: "text",
    helptext: "Please provide the name of the user.",
    defaultValue: 'Name',
    validatorConfig: { isRequired: true, minLen: 1, maxLen: 50}
    },

  email: { id: "email", label: "Email", type: "text",
    helptext: "Please provide a valid email address for the user account.",
    defaultValue: 'me@unknown.com',
    validatorConfig: { isRequired: true, minLen: 1, maxLen: 50}
    },

  profile_pic: { id: "profile_pic", label: "Profile Picture", type: "file",
    helptext: "Please attach a picture for the user",
    defaultValue: null
    },
};

const USER_PROFILE_FORM_INFO = {
  name: "User Profile",
  url: LIFEDOCS_SERVER_URLS.userProfiles,
  sections: [
    {
      id: 'userprofile-information',
      name: "User Profile (Test)",
      description: "Form Information for uploading User Profiles",
      ux_info: {

        nextLabel: 'Add User Profile',
        isGetList: true
      },
      data: USER_PROFILE_FORM_DATA
    }
  ]
}
*/

class StudentProfileEntry {
  constructor( data) {

    this.createdAt = new Date();
    // Full Name (First Name, Last Name):
    this.first_name = data.first_name;
    this.last_name = data.last_name;

    // email
    this.email = data.email;

    // contact information
    this.address_line1 = data.address_line1;
    this.address_line2 = data.address_line2 || '';
    this.city = data.city;
    this.state = data.state;
    this.zipcode = data.zipcode;

    // phone numbers
    // this.home_number = data.home_number;
    this.cell_number = data.cell_number;

    // demographics
    this.gender = data.gender;

    // school information
    this.highschool = data.highschool;
    this.grade = data.grade; // Grade in High School for the year  (current school year)

    // Parent Information
    this.parent_first_name = data.parent_first_name;
    this.parent_last_name  = data.parent_last_name;
    this.parent_email = data.parent_email;
    this.parent_cell_number = data.parent_cell_number;

    // Emergency contact
    this.emergency_name = data.emergency_name;
    this.emergency_phone_number = data.emergency_phone_number;

    // fee qualifier
    // Do you qualify for any fee waiver:
    // If qualify for fee waiver provide explanation:
    this.is_fee_waiver = data.is_fee_waiver || false;
    this.reason_fee_waiver = data.reason_fee_waiver || '';

    this.interests = data.interests || '';
    this.referral_from = data.referral_from || '';
    this.comments = data.comments || '';
    this.accessFrom = data.accessFrom;
  }


  print() {
    console.log("\n Student Profile");
    console.log( `Email: ${this.email}`);
    console.log( `First Name: ${this.first_name}`);
    console.log( `Last Name: ${this.last_name}`);

    console.log( `Address Line 1: ${this.address_line1}`);
    console.log( `Address Line 2: ${this.address_line2}`);
    console.log( `City: ${this.city}`);
    console.log( `State: ${this.state}`);
    console.log( `Zipcode: ${this.zipcode}`);

    // this.home_number,
    console.log( `Phone Number: ${this.cell_number}`);

    // demographics
    console.log( `Gender: ${this.gender}`);

    // school information
    console.log( `High School: ${this.highschool}`);
    console.log( `Grade: ${this.grade}`);

    // Parent Information
    console.log( `Parent Email: ${this.parent_email}`);
    console.log( `Parent First Name: ${this.parent_first_name}`);
    console.log( `Parent Last Name: ${this.parent_last_name}`);
    console.log( `Parent Phone Number: ${this.parent_cell_number}`);

    console.log( `Emergency Contact: ${this.emergency_name}`);
    console.log( `Emergency Phone Number: ${this.emergency_phone_number}`);

    // fee qualifier
    // Do you qualify for any fee waiver:
    // If qualify for fee waiver provide explanation:
    console.log( `Is Fee Waiver: ${this.is_fee_waiver}`);
    console.log( `Reason for Waiver: ${this.reason_fee_waiver}`);

    console.log( `Interests: ${this.interests}`);
    console.log( `Referral From: ${this.referral_from}`);
    console.log( `Comments: ${this.comments}`);

    console.log( `Access From: ${this.accessFrom}`);
  }

  asArray() {
    return [
      // this.createdAt,
      // Full Name (First Name, Last Name):
      this.first_name,
      this.last_name,

      // email
      this.email,

      // contact information
      this.address_line1,
      this.address_line2,
      this.city,
      this.state,
      this.zipcode,

      // phone numbers
      // this.home_number,
      this.cell_number,

      // demographics
      this.gender,

      // school information
      this.highschool,
      this.grade,

      // Parent Information
      this.parent_first_name,
      this.parent_last_name ,
      this.parent_email,
      this.parent_cell_number,

      // Emergency contact
      this.emergency_name,
      this.emergency_phone_number,

      // fee qualifier
      // Do you qualify for any fee waiver:
      // If qualify for fee waiver provide explanation:
      this.is_fee_waiver,
      this.reason_fee_waiver,

      this.interests,
      this.referral_from,
      this.comments,
      this.accessFrom
    ]
  }

}

function addToStudentProfile( studentProfileEntry) {

  // console.log( `About to submit entry to newsletter. ${JSON.stringify(newsletterEntry)}`);
  // For now we record the data in both Google Sheet and Firestore

  // 1. Submit to GoogleSheet
  // console.log( process.env.REACT_APP_STUDENT_PROFILE_IN_GOOGLE_SHEET);
  if ( process.env.REACT_APP_STUDENT_PROFILE_IN_GOOGLE_SHEET === 'true') {
    GoogleSheet
      .submit(
        process.env.REACT_APP_SHEETS_ENDPOINT,
        process.env.REACT_APP_STUDENT_PROFILE_TAB_ID,
        studentProfileEntry.asArray());
  }

  // 2. add to the Firesetore database
  return StudentProfileDbObject.CreateObject( studentProfileEntry);
}

function setStudentProfile( id, studentProfileEntry) {

  // console.log( `About to submit entry to newsletter. ${JSON.stringify(newsletterEntry)}`);
  // For now we record the data in both Google Sheet and Firestore

  // 1. Submit to GoogleSheet
  // console.log( process.env.REACT_APP_STUDENT_PROFILE_IN_GOOGLE_SHEET);
  if ( process.env.REACT_APP_STUDENT_PROFILE_IN_GOOGLE_SHEET === 'true') {
    GoogleSheet
      .submit(
        process.env.REACT_APP_SHEETS_ENDPOINT,
        process.env.REACT_APP_STUDENT_PROFILE_TAB_ID,
        studentProfileEntry.asArray());
  }

  // 2. add to the Firesetore database
  return StudentProfileDbObject.SetObject( id, Object.assign({}, studentProfileEntry));
}

function getStudentProfile( id) {

  // 1. get from the Firesetore database
  const studentProfileEntry = StudentProfileDbObject.UseObject( id);
  return studentProfileEntry;
}

const StudentProfileService = {
  add: addToStudentProfile,
  set: setStudentProfile,
  get: getStudentProfile,
};


/*
// Use following for the regular ES6 module inclusion in React
if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
  // console.log( "Exporting via Module");
  module.exports.StudentProfile = StudentProfile;
} else {
  // console.log( "Exporting via Window Manager");
  window.StudentProfile = { StudentProfile: StudentProfile }
}
// For Testing
const SPMODULE = require( './student_profile');
const demoData = { first_name: 'Test', last_name: 'LastName'};
const sp = new SPMODULE.StudentProfile( demoData);
console.log( sp);

*/

export default StudentProfileService;
export { StudentProfileEntry };
