import React from 'react';
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";

import AppConfigs from './../../configs';
import TimelineSection from "./TimelineSection";

const my_components = {
  "TIMELINE": () => (<TimelineSection timeline_actions={AppConfigs.actions_for_timeline}/>)
};

const RenderComponent = ( {component_id}) => {
  // component does exist
  return (typeof(my_components[component_id])  !== "undefined")
      ? React.createElement(my_components[component_id])
      : null;
}

export default RenderComponent;
