const team_bios = [
  {
    avatar: "/images/portrait-dr-mani-vadari-2.jpg",
    name: "Dr. Mani Vadari",
    role: "Founder and President",
    bio: [
      "Dr Mani Vadari founded Spark Summer Internship Program, a Washington State non-profit company to focus on creating internship opportunities for high school kids in the greater Seattle area. Mani has a deep passion in mentoring people of all ages and has decided to channel that passion to working with various entities in the greater Seattle area to provide a broad range of internship opportunities to high school students.",
      "Mani is also founder and President of Modern Grid Solutions delivering consulting and training services to cities, utilities and vendors across the globe. Mani offers deep subject matter expertise in developing the next-generation electric energy and other sustainable mechanisms. Dr. Vadari is also an Affiliate Professor at the University of Washington in Seattle. Mani also serves on the Board of WhyGreen and a strategic advisor of Optio3’s board.",
      "Mani is a sought-after industry speaker and a member of several industry boards. Mani is an active member of the National Association of Corporate Directors (NACD). Lastly, Mani is founder, and board member of Seattle’s Hindu Temple, and founded 3 non-profit companies – Girls Rock in Science & Math, House of Kala, and SPARK."
    ]
  },
  {
    avatar: "/images/portrait-murali.jpg",
    name: "Murali Krishnan",
    role: "Chief Technology Officer and Advisor",
    bio:[
      "In his formative years, Murali received help from many people. He is thankful for all the support received. SparkSIP is one way of paying back in kindness for help received. Murali has mentored a few SparkSIP team of diverse students himself and enjoys the experience of witnessing students grow.",
      "Murali is a software executive building and leading global software teams. He is the President of Zanavu Ventures, focused on angel investing and technology incubation.",
      "Murali has led global software teams at Microsoft Corp., Starbucks Coffee Company, and startup companies spanning the consumer and enterprise business in mobile and cloud computing.",
    ]
  },
  {
    avatar: "/images/portrait-anu-vadari.jpg",
    name: "Anu Vadari",
    role: "Chief Operating Officer",
    bio:[
      "Anu is passionate about mentoring students and guiding them to find tools and resources to get their passion to the next level. She works closely with the providers, students, parents and takes care of the general administration and community outreach to spread the word about SPARK.",
      "Anu is a senior software engineer at GE working as a SCADA Subject Matter Expert serving as a Technical lead focused on direct customer interaction and tendering.",
      "She is also the COO of House of Kala, and an active volunteer at the North South Foundation Bee competitions.",
    ]
  },

];

export default team_bios;
