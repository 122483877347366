import React from "react";
import SponsorActions from "./../components/SponsorSection";
// import { requireAuth } from "./../util/auth.js";

function SponsorPage(props) {

  return (
      <SponsorActions.show_sponsor_form
        bg="bisque"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Sponsor Project"
        subtitle=""
        buttonText="Submit Project"
        buttonColor="success"
        showNameField={true}
      />
  );

}

// export default requireAuth(SponsorPage);
export default SponsorPage;
