
const map_of_communities = {
  'yc': { name: "YCombinator"},
  'madrona': { name: "Madrona Venture Group" },
  'trilogy': { name: "Trilogy Equity Partners"},

  'bellevuecc': { name: "Bellevue Chamber of Commerce"},
  'seattlecc': { name: "Seattle Chamber of Commerce"},

  'uw': { name: "University of Washington"},
  'ceg': { name: "College of Engineering, Guindy"},
  'iim': { name: "Indian Institute of Management"},

  'geekwire': { name: "Geekwire"},
  'techcrunch': { name: "Techcrunch"},
  'hn': { name: "Hacker News"},

  'default': { name: "Business"},
};

export default map_of_communities;
