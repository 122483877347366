import React from 'react';

const formatToParas = ( arrayOfText) => {
  return (<ul>
    {arrayOfText.map( (item, index) => (
      <li key={index}>{item}</li>
  ))}
  </ul>)
};

const formatToParas2 = ( arrayOfText) => {
  return arrayOfText.map( (item, index) => (
    <p key={index}>{item}</p>
  ))
};

export { formatToParas};
