import React from "react";
import AppConfigs from './../../configs';
import { StudentPreparation_UnAuthenticated, StudentPreparation_Authenticated }
  from './StudentActions.Prepare';
import StudentPreregister from './StudentActions.PreRegister';
import StudentProfile from './StudentActions.Profile';
import StudentProjects from './StudentActions.Projects';
import StudentReferral from './StudentActions.Refer';
import StudentPayment from './StudentActions.Payment';
import StudentSendApp from './StudentActions.SendApp';
import StudentSubmitApp from './StudentActions.SubmitApp';
import StudentInterview from './StudentActions.Interview';
import CompleteInternship from './StudentActions.CompleteInternship';

const StudentActions = {
  'prepare_learn':StudentPreparation_UnAuthenticated,
  'prepare': StudentPreparation_Authenticated,
  'preregister': StudentPreregister,

  'profile': StudentProfile,
  'projects': StudentProjects,

  'refer': StudentReferral,
  'pay': StudentPayment,

  'send_app': StudentSendApp,
  'submit_app': StudentSubmitApp,

  'interview_selection': StudentInterview,
  'complete_internship':  CompleteInternship,
};

export default StudentActions;
