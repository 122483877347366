import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import FormField, {FormSelect, FormReferralFrom} from "./../../components/FormField";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import StudentProfileService, { StudentProfileEntry} from "./../util/student_profile.js";
import { useForm } from "react-hook-form";
import { useAuth } from "./../../util/auth.js";

const StudentProfileFormFields = (props) => {
  const { user, items } = props;

  const { handleSubmit, register, errors } = useForm();

  const onSubmit = ({
    first_name, last_name, email,
    address_line1, address_line2, city, state, zipcode,
    cell_number,  gender,  highschool, grade,
    parent_first_name, parent_last_name, parent_email, parent_cell_number,
    emergency_name, emergency_phone_number,
    is_fee_waiver, reason_fee_waiver,
    interests, referral_from, comments, accessFrom
  }) => {

    const studentProfileEntry = new StudentProfileEntry( {
      first_name, last_name, email,
      address_line1, address_line2, city, state, zipcode,
      cell_number,  gender,  highschool, grade,
      parent_first_name, parent_last_name, parent_email, parent_cell_number,
      emergency_name, emergency_phone_number,
      is_fee_waiver, reason_fee_waiver,
      interests, referral_from, comments,
      accessFrom: props.accessFrom
    });

    StudentProfileService.set( user.uid, studentProfileEntry)
      .then( (result) => {

        // Parent component can optionally
        // find out when subscribed.
        props.onSubscribed && props.onSubscribed( {first_name, last_name});
        // console.log( `StudentProfileForm: Result from submission: ${JSON.stringify(result)}`);
      })
      .catch( err => {

        console.log( `StudentProfileForm: Unable to submit. We will look into the failure. Thanks.`);
        console.log( err);
      })
      ;
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <hr/>
      <h4>Student Information</h4>

      <Form.Row>
        <Form.Group as={Col} xs={12} sm={6} controlId="formFirstName">
          <FormField size="md"
            label="First Name"
            name="first_name"
            type="text"
            placeholder="First Name"
            defaultValue={ items && items.first_name || ""}
            error={errors.first_name}
            inputRef={register({
              required: "Please enter First Name",
            })}
          />
        </Form.Group>
        <Form.Group as={Col} xs={12} sm={6} controlId="formLastName">
          <FormField size="md"
            label="Last Name"
            name="last_name"
            type="text"
            placeholder="Last Name"
            defaultValue={ items && items.last_name || ""}
            error={errors.last_name}
            inputRef={register({
              required: "Please enter Last Name",
            })}
          />
        </Form.Group>
        </Form.Row>
        <Form.Row>
        <Form.Group as={Col} xs={12} sm={6} controlId="formEmail">
          <FormField size="md"
            label="Email"
            name="email"
            type="email"
            placeholder="Email"
            defaultValue={ items && items.email || user.email || ""}
            error={errors.email}
            inputRef={register({
              required: "Please enter email",
            })}
          />
        </Form.Group>
        <Form.Group as={Col} xs={12} sm={6} controlId="formCellNumber">
          <FormField size="md"
            label="Cell/Phone Number"
            name="cell_number"
            type="phone"
            placeholder="Cell Number"
            defaultValue={ items && items.cell_number || ""}
            error={errors.cell_number}
            inputRef={register({
              required: "Please enter Cell / Phone Number",
            })}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} xs={12} sm={6} controlId="formAddress_line1">
          <FormField size="md"
            label="Address Line 1"
            name="address_line1"
            type="text"
            placeholder="Address Line 1"
            defaultValue={ items && items.address_line1 || ""}
            placeholder="Address_line1"
            error={errors.address_line1}
            inputRef={register({
              required: "Please enter Address Line 1",
            })}
          />
        </Form.Group>
        <Form.Group as={Col} xs={12} sm={6} controlId="formAddress_line2">
          <FormField size="md"
            label="Address Line 2"
            name="address_line2"
            type="text"
            placeholder="Address Line 2"
            defaultValue={ items && items.address_line2}
            error={errors.address_line2}
            inputRef={register({
              // optional: required: "Please enter address_line2",
            })}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} xs={12} sm={6} controlId="formCity">
          <FormField size="md"
            label="City"
            name="city"
            type="text"
            placeholder="City"
            defaultValue={ items && items.city}
            error={errors.city}
            inputRef={register({
              required: "Please enter city",
            })}
          />
        </Form.Group>
        <Form.Group as={Col} xs={12} sm={2} controlId="formState">
          <FormSelect
            label="State"
            name="state"
            type="state"
            placeholder="State"
            defaultValue={ items && items.state}
            error={errors.state}
            inputRef={register({
              required: "Please enter the US State where you reside in.",
            })}
            select_options={[  "WA", "CA"]}
          />
        </Form.Group>
        <Form.Group as={Col} xs={12} sm={2} controlId="formZipcode">
          <FormField size="md"
            label="Zipcode"
            name="zipcode"
            type="number"
            placeholder="Zipcode"
            defaultValue={ items && items.zipcode}
            error={errors.zipcode}
            inputRef={register({
              required: "Please enter zipcode",
            })}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} xs={12} sm={6} controlId="formHighschool">
          <FormField size="md"
            label="Highschool"
            name="highschool"
            type="text"
            placeholder="High School"
            defaultValue={ items && items.highschool}
            error={errors.highschool}
            inputRef={register({
              required: "Please enter High School name",
            })}
          />
        </Form.Group>
        <Form.Group as={Col} xs={12} sm={2} controlId="formGrade">
          <FormSelect size="md"
            label="Grade"
            name="grade"
            type="text"
            placeholder="11"
            defaultValue={ items && items.grade}
            error={errors.grade}
            inputRef={register({
              required: "Please enter grade",
            })}
            select_options={[  "9", "10", "11", "12"]}
          />
        </Form.Group>
        <Form.Group as={Col} xs={12} sm={2} controlId="formGender">
          <FormSelect
            size="md"
            label="Gender"
            name="gender"
            type="text"
            placeholder=""
            defaultValue={ items && items.gender}
            select_options={[  "", "Male", "Female"]}
            error={errors.gender}
            inputRef={register({
              // required: "Please enter Gender",
            })}
          />
        </Form.Group>
      </Form.Row>

      <hr/>
      <h4>Parent Information</h4>

      <Form.Row>
        <Form.Group as={Col} xs={12} sm={6} controlId="formParent_first_name">
          <FormField size="md"
            label="Parent / Guardian First Name"
            name="parent_first_name"
            type="text"
            placeholder="Parent First Name"
            defaultValue={ items && items.parent_first_name}
            error={errors.parent_first_name}
            inputRef={register({
              required: "Please enter Parent First Name",
            })}
          />
        </Form.Group>
        <Form.Group as={Col} xs={12} sm={6} controlId="formParent_last_name">
          <FormField size="md"
            label="Parent / Guardian Last Name"
            name="parent_last_name"
            type="text "
            placeholder="Parent Last Name"
            defaultValue={ items && items.parent_last_name}
            error={errors.parent_last_name }
            inputRef={register({
              required: "Please enter Parent Last Name ",
            })}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} xs={12} sm={6} controlId="formParent_email">
          <FormField size="md"
            label="Parent / Guardian  Email"
            name="parent_email"
            type="email"
            placeholder="Parent Email"
            defaultValue={ items && items.parent_email}
            error={errors.parent_email}
            inputRef={register({
              required: "Please enter Parent Email",
            })}
          />
        </Form.Group>
        <Form.Group as={Col} xs={12} sm={6} controlId="formParent_cell_number">
          <FormField size="md"
            label="Parent / Guardian Cell Number"
            name="parent_cell_number"
            type="number"
            placeholder="Parent Cell Number"
            defaultValue={ items && items.parent_cell_number}
            error={errors.parent_cell_number}
            inputRef={register({
              required: "Please enter a valid Parent Cell Number",
            })}
          />
        </Form.Group>
      </Form.Row>

      <hr/>
      <h4>Emergency Contact Information</h4>

      <Form.Row>
        <Form.Group as={Col} xs={12} sm={6} controlId="formEmergency_name">
          <FormField size="md"
            label="Emergency Contact Name"
            name="emergency_name"
            type="text"
            placeholder="Emergency Contact Name"
            defaultValue={ items && items.emergency_name}
            error={errors.emergency_name}
            inputRef={register({
              required: "Please enter emergency_name",
            })}
          />
        </Form.Group>
        <Form.Group as={Col} xs={12} sm={6} controlId="formEmergency_phone_number">
          <FormField size="md"
            label="Emergency Phone Number"
            name="emergency_phone_number"
            type="number"
            placeholder="Emergency Phone Number"
            defaultValue={ items && items.emergency_phone_number}
            error={errors.emergency_phone_number}
            inputRef={register({
              required: "Please enter emergency_phone_number",
            })}
          />
        </Form.Group>
      </Form.Row>

  {/*}<!-- Keep fee waiver stuff off for now
      <hr/>
      <h4>Fee Waiver <small>(Not All students qualify for this)</small></h4>

      <Form.Row>
        <Form.Group as={Col} xs={12} sm={10} controlId="formIs_fee_waiver">
          <FormField size="md"
            label="Request Fee Waiver"
            name="is_fee_waiver"
            type="text"
            placeholder="Is_fee_waiver"
            error={errors.is_fee_waiver}
            inputRef={register({
              // Optional: required: "Please enter is_fee_waiver",
            })}
          />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} xs={12} sm={10} controlId="formReason_fee_waiver">
          <FormField size="md"
            label="Reason_fee_waiver"
            name="reason_fee_waiver"
            type="text"
            placeholder="Reason_fee_waiver"
            error={errors.reason_fee_waiver}
            inputRef={register({
              // Optional: required: "Please enter reason_fee_waiver",
            })}
          />
        </Form.Group>
      </Form.Row>
  -->*/}
      <hr/>
      <h4>Other Comments</h4>

      <Form.Row>
        <Form.Group as={Col} xs={12} sm={7} controlId="formInterests">
          <FormField size="md"
            label="Interests"
            name="interests"
            type="text"
            defaultValue={ items && items.interests}
            placeholder="Interests (please multiple items are separated with a comma)"
            error={errors.interests}
            inputRef={register({
              // required: "Please enter interests",
            })}
          />
        </Form.Group>

      <FormReferralFrom
          xs_cols={12}
          sm_cols={5}
          size="md"
          label="How did you hear about SparkSIP?"
          name="referral_from"
          error={errors.referral_from}
          inputRef={register({})} // option entry, so not required
        />
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} xs={12} controlId="formComments">
          <FormField size="md"
            label="Comments"
            name="comments"
            type="textarea"
            rows={3}
            placeholder="Comments"
            defaultValue={ items && items.comments}
            error={errors.comments}
            inputRef={register({
              // optional : required: "Please enter comments",
            })}
          />
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Col xs="auto">
          <Button variant={props.buttonColor} size="md" type="submit">
            {props.buttonText}
          </Button>
        </Col>
      </Form.Row>
    </Form>
  );
}

export default StudentProfileFormFields;
