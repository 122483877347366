import { v4 as uuid } from 'uuid';

// As of 2/3/2021 - we are going through nocodeapi.com
//  which means for every call there is a charge accumulated
// ToDo: migrate to direct calls with googlesheet OR use another data source

function submit_to_sheet( sheet, tab, dataAsArray) {

  const endpoint = sheet + '?tabId=' + tab;

  // automatically add a date field and id field
  const dateNow = new Date();
  const id = uuid();

  return fetch(endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify( [[dateNow, id, ...dataAsArray]] ),
  }).then((r) => r.json());
}

/*
// Get is useful to retrieve data. But for now without authentication, we do not need this.

function get_from_sheet( sheet, tab, dataAsArray) {

  const endpoint = sheet + '?tabId=' + tab + "&api_key=" + process.env.REACT_SHEETS_API_KEY;

  return fetch(endpoint, { method: "GET"}).
    then((r) => r.json());
}
*/

const GoogleSheet = {
//   get: get_from_sheet,
  submit: submit_to_sheet,
};

export default GoogleSheet;
