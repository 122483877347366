const featured_business = [
  {
    name: "6crickets",
    image: "https://www.6crickets.com/_assets/images/logo.png",
    width: "90px",
  },
  {
    name: "AppSheet",
    image: "https://www.appsheet.com/content/img/material/logo.svg",
    width: "150px",
  },
  {
    name: "BizX",
    image: "https://www.bizx.com/hs-fs/hubfs/BizX%20Logo%202014%20Web-1.png",
    width: "150px",
  },
  {
    name: "Cartogram",
    image: "https://www.cartogram.com/wp-content/uploads/2020/07/Cartogram-Logo-min.png",
    width: "135px",
  },
  {
    name: "White Matter",
    image: "https://white-matter.com/img/logo no text.779d3f67.svg",
    width: "135px",
  },
  {
    name: "University of Washington",
    image: "https://s3-us-west-2.amazonaws.com/uw-s3-cdn/wp-content/uploads/sites/98/2014/09/07214435/Signature_Stacked_Purple_Hex-300x148.png",
    width: "135px",
  },
];

export default featured_business;
