import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./SectionHeader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AppConfigs from './../configs';

function ClientsSection(props) {
  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className="text-center">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
        />
        <Row className="justify-content-center">
          {AppConfigs.featured_business.map((item, index) => (
            <Col md="auto" className="py-3 px-4" key={index}>
              <div className="align-bottom">
                <img src={item.image} height="40px" alt={item.name} />
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  );
}

export default ClientsSection;
