import React from "react";
import HeroSection2 from "./../components/HeroSection2";
import StatsSection from "./../components/StatsSection";
import TeamBiosSection from "./../components/TeamBiosSection";
import AppConfigs from '../configs';

function AboutPage(props) {
  return (
    <>
      <HeroSection2
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="SparkSIP helps students and businesses"
        subtitle="Our vision is to connect talented high school students to work with industry experts, businesses, and academic institutions. Working in a professional environment prepares students intellectually and emotionally for the future."
      />
      <StatsSection
        bg="light"
        textColor="dark"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        stats={AppConfigs.stats_data}
      />
      <TeamBiosSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Meet the Team"
        subtitle=""
        team_bios={AppConfigs.team_bios}
      />
    </>
  );
}

export default AboutPage;
