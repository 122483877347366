import React from "react";
import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";

const PrepareApplicationMaterials = () => (
  <>
  <p>Students should review the required information and
    gather the materials ahead of deadlines.
  </p>
  <h5>Project Selection</h5>
  <p>
  We recommend the students to prepare ahead of time for the application.
  Look at the <a href="/projects">Projects</a> to learn and decide on
  which projects to apply for.
  This will help the the application package to be fine-tuned
    to the internship requirements.
  One or more essays will need to be written.
  Start writing drafts of the essays.
  </p>
  <p>
  </p>
  <h5>Eligibility Criteria</h5>
  <ul>
    <li>
    Need to be a current high school student in the K-12 school system for the year 2020-2021.
    Students from college preparatory programs such as UW Transition or UW Academy will not be eligible.
      Also, rising 8th graders also will not be eligible.
    </li>
    <li>
    Need to be able to spend the specified time 30-40 hours per week for 8-12 weeks
    during the summer (June - August) for the internship
      preferably in the time zone of the internship provider.
    </li>
    <li>
      For this 2021 summer, we are supporting the students from
        the states of Washington and California in the United States.
      Please be patient with us as we learn what it means to accommodate students
        from any other states or outside the USA.
    </li>
  </ul>
  <h5>Application Components</h5>
  <p>
  Students will be asked to prepare following materials for submission:
  </p>
  <ul>
    <li><strong>Student Profile</strong> (Contact number/email, high school information, etc.)
      &nbsp; Fill in<a href="/students/profile">
      Profile</a>.
    </li>
    <li><strong>Legible Resume</strong> &nbsp;
      See <a href="https://blog.sparksip.org/2021/03/resume-preparation">
      Resume Preparation Tips</a>
    </li>
    <li><strong>Copy of School Transcript</strong> in PDF format.
    (Please obtain this from your school office.
      We need the school name as well as student name clearly printed
      and ALL your high school grades. Screenshots will not be accepted.)
    </li>
    <li><strong>Personal Essay</strong> for selected projects. See more &nbsp;
    <a href="https://blog.sparksip.org/2021/03/personal-essay-for-sparksip-application/">
    here</a>. Yes, <a href="https://blog.sparksip.org/2021/03/yes-essay-is-per-project/">each project gets its own essay</a>.
    Kindly please write <strong>questions</strong> when you write the essay per project.
      </li>
    <li><strong>SparkSIP Parent Student Consent</strong>
      &nbsp;
      <a href="/docs/2021-03-09-Spark-Parent-Student-Consent-form.pdf">Form</a>
    </li>
  </ul>
  <h5>Application Fees</h5>
  <p>SparkSIP collects a small fee to handle the preparation, seeking of projects,
  and vetting process. Bulk of the work is done by volunteers who help
  the non-profit. Please note that the fee collected is dependent on
    the number of projects that are targeted by an applicant.
  </p>
  </>
);

const StudentPreparation_UnAuthenticated = {
  id: "prepare",
  name: "Prepare",
  status: "active",
  title: "Prepare Application",
  subtitle: "Application Deadline is: " + process.env.REACT_APP_DEADLINE + ".",

  onNow: true,  /* override missing buttonPath to still show the section as active */
  bg: "celeste",
  textColor: "dark",
  size: "md",

  imgsrc:"/images/stand_front_star1.svg",
  imgalt:"Prepare Application Materials",
  imgcols: 2,
  imgheight:"300px",

  buttonText:"Get Started",
  buttonColor:"success",
  buttonPath:"/preregister",

  messageBody: (<PrepareApplicationMaterials />)
};

// make minor changes for the Authenticated context
let StudentPreparation_Authenticated = {
  ...StudentPreparation_UnAuthenticated,
  bg: "bisque",
  buttonPath:"/students/profile",
};

export { StudentPreparation_UnAuthenticated, StudentPreparation_Authenticated };
