
import React from "react";
import BusinessActionsDetail from './../components/BusinessActionsDetail';
import RenderMultipleSections from './../components/ActionContainerSection';

function BusinessDashboardSection(props) {

  const styleAttributes = {
    bg: "white",
    textColor: "dark",
    size: "md",
    bgImage: "",
    bgImageOpacity: 1
  };

  const sections = [
    BusinessActionsDetail.community_header,
    BusinessActionsDetail.sponsor_project,
    BusinessActionsDetail.review_candidates,
    BusinessActionsDetail.select_candidates,
    BusinessActionsDetail.complete_internship
  ];

  return (
    <RenderMultipleSections sections={sections}
        firstTitle={"Hello Business Community"}
        {...props}/>
  );
}

export default BusinessDashboardSection;
