// Map our custom plan IDs ("basic", "premium", etc) to Stripe price IDs
const stripePriceIds = {
  student_quickstart: process.env.REACT_APP_STRIPE_PRICE_QUICK_START,
  // student_seekmore: process.env.REACT_APP_STRIPE_PRICE_SEEK_MORE,
  // student_allin: process.env.REACT_APP_STRIPE_PRICE_ALLIN,
  student_allin: process.env.REACT_APP_STRIPE_PRICE_ALLIN_FROM_APR3,
  student_allin_murali: process.env.REACT_APP_STROPE_PRICE_ALLIN_MURALI,
  business: process.env.REACT_APP_STRIPE_PRICE_BUSINESS,
};

// Get Stripe priceId
export function getStripePriceId(planId) {
  let priceId = stripePriceIds[planId];
  // console.log( `input plan = ${planId} => price id: ${priceId}` );
  return priceId;
}

// Get friendly plan ID ("basic", "premium", etc) by Stripe plan ID
// Used in auth.js to include planId in the user object
export function getFriendlyPlanId(stripePriceId) {
  return Object.keys(stripePriceIds).find(
    (key) => stripePriceIds[key] === stripePriceId
  );
}
