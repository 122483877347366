import React from 'react';
import { useAuth, requireAuth } from './../../util/auth.js';
import { useItemsByOwner } from './../../util/db.js';
import { Route, Link, useRouter } from "./../../util/router.js";
import ProdTestPricingPage from "./pricing_prod_test";
import ZItemszReferralPage from "./z_item_referral";

// ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- -----
/**** Local Routes ****/
// ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- -----

const allZItemz = [
  {
    name: 'ZItemz Home Page (default)',
    id: '',
    component: (props) => (<ZItemzHome {...props}/>)
  },
  {
    name: 'ZItems Items',
    id: 'items',
    component: (props) => (<ShowItemsPage {...props} />)
  },
  {
    name: "(PROD TESTING) PURCHASE ALL_IN",
    id: "zpurchase",
    component: (props) => ( <ProdTestPricingPage {...props} />)
  },
  {
    name: "(TESTING) ZITEMZ: Referral",
    id: "zreferral",
    component: (props) => ( <ZItemszReferralPage {...props} />)
  },

];

// ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- -----
/**** ZItemz ****/
// ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- -----

const ItemsList = ( {items} ) => {

  // for trial, just dump the items.
  return (
    <>
    {items.length
      ? items.map( (item, i) => (
      <div key={i}>
        <p> {JSON.stringify(item)} </p>
      </div>
      ))
      : (<p>No Items were found </p>)
    }
    </>
  );
}

// let us directly get data from the "items" collection to show
function ShowItemsPage(){
  const auth = useAuth();

  // Fetch items by owner
  // Returned status value will be "idle" if we're waiting on
  // the uid value or "loading" if the query is executing.
  const uid = auth.user ? auth.user.uid : "mu";
  const itemsReturned = useItemsByOwner(uid);
  const { data: items, status, error } = itemsReturned;

  // Once we have items data render ItemsList component
  // ToDo: handle status === "error"
  return (
    <div>
      {(status === "idle" || status === "loading")
      ? (
        <span>One moment please. We are fetching the items</span>
      )
      : (status === "success")
      ? (
        <ItemsList items={items} />
      )
      : (
        <p>{JSON.stringify( error)}</p>
      )
    }
    </div>
  );
}

function ZItemsNavIndex( props) {

  const { match } = useRouter();

  // trim the training slash or else path routing is messed up
  const urlPrefix = (match.url[match.url.length-1] === '/')
    ? match.url.slice(0,match.url.length-1) : match.url;

  return (
    <div>
      <h1>ZItemz</h1>
      <ul>
        {allZItemz.map(({ name, id }) => (
          <li key={id}>
            <Link to={`${urlPrefix}/${id}`}>{name}</Link>
          </li>
        ))}
      </ul>

      <hr />
    </div>
  );
}

function ZItemzHome() {
  const { match } = useRouter();

  return (
    <>
      <ZItemsNavIndex />
    </>
  )
}

function ZItemszPage ( props) {

  const { match } = useRouter();

  return (
    <div>
      {allZItemz.map(({ name, id, component }) => (
          <Route exact path={`${match.path}/${id}`} component={component} key={id}/>
      ))}

    </div>
  )
}


/*
<ZItemsNavIndex />
<Route path={`${match.path}/`} component={ZItemzHome}/>

<Route path={`${match.path}/:itemId`}>
  <ZItemzTopics/>
</Route>
*/
export default ZItemszPage;
