import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import FormField from "./../../components/FormField";
import Button from "react-bootstrap/Button";
import NewsletterService, { NewsletterEntry} from "./../util/newsletter.js";
import { useForm } from "react-hook-form";

function NewsletterForm(props) {
  const [subscribed, setSubscribed] = useState(false);
  const { handleSubmit, register, errors } = useForm();

  const onSubmit = ({ email, location, highschool, interests }) => {

    const newsletterEntry = new NewsletterEntry( { email, location, highschool, interests, accessFrom: props.accessFrom })

    NewsletterService.addToNewsletter( newsletterEntry)
      .then( (result) => {
        setSubscribed(true);
        // Parent component can optionally
        // find out when subscribed.
        props.onSubscribed && props.onSubscribed();
        // console.log( `NewsletterForm: Result from submission: ${JSON.stringify(result)}`);
      })
      .catch( err => {

        console.log( `NewsletterForm: Unable to submit. We will look into the failure. Thanks.`);
        console.log( err);
      })
      ;
  };

  return (
    <>
      {subscribed === false && (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Row>
            <Form.Group as={Col} xs={12} sm={10}
            controlId="formEmail"
            >
              <FormField
                size="md"
                label="Email"
                name="email"
                type="email"
                placeholder="Email"
                error={errors.email}
                inputRef={register({
                  required: "Please enter an email address",
                })}
              />
            </Form.Group>
          </Form.Row>

          { ['homepage', 'student'].includes(props.accessFrom) &&
          <Form.Row>
            <Form.Group as={Col} xs={12} sm={10}
            controlId="formSchool"
            >
              <FormField
                size="md"
                label="Your High School"
                name="highschool"
                type="text"
                placeholder="Highschool Name"
                error={errors.highschool}
                inputRef={register({
                  required: "Please enter your highschool name",
                })}
              />
            </Form.Group>
          </Form.Row>
          }

          <Form.Row>
            <Form.Group as={Col} xs={12} sm={10}
            controlId="formLocation"
            >
              <FormField
                size="md"
                label="Your Location"
                name="location"
                type="text"
                placeholder="City, State ZIPCODE"
                error={errors.location}
                inputRef={register({
                  required: "Please enter your city",
                })}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} xs={12} sm={10}
            controlId="formInterests"
            >
              <FormField
                size="md"
                label="Your Interests"
                name="interests"
                type="text"
                placeholder="Your Interests (use commas to separate multiple interests)"
                inputRef={register}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Col xs="auto">
              <Button variant={props.buttonColor} size="md" type="submit">
                {props.buttonText}
              </Button>
            </Col>
          </Form.Row>
        </Form>
      )}

      {subscribed === true && <div>{props.subscribedMessage}</div>}
    </>
  );
}

export default NewsletterForm;
