
const actions_for_timeline = [
  { month: "February", title: "Sponsor Projects",
    detail: ["20-40 hours per week.", "Max Stipend $500."]},
  { month: "March", title: "Students Apply",
    detail: ["Apply for 1-5 projects.", "Submit essays and transcripts."]},
  { month: "April", title: "Applicants Matched", status: "active",
    detail: ["Applications reviewed.", "Applications sent to sponsors."]},
  { month: "May", title: "Interviews",
    detail: ["Applicant and Sponsor meet.", "Business submits candidate list."]},
  { month: "June", title: "Applicants Matched",
    detail: ["Applicants accept internship.", "Internship begins."]},
  { month: "June - August", title: "Internship",
    detail: ["Work Hard.", "Do and Learn.", "Have Fun!"]},
];

export default actions_for_timeline;
