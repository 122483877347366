import React from "react";
import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";

// Send is a quick option to have students email out the application
//  instead of doing online submissions

const SendApplicationMaterials = ( props) => (
  <>
    <p>
    Thank you for preparing the application for SparkSIP Internship.
    </p>
    <h5>Preparation Steps</h5>
    <p>
    Make sure to handle the following steps in sequence
      <LinkContainer to="/students/profile">
        <Nav.Link eventKey="profile">Fill in Student Profile</Nav.Link>
      </LinkContainer>
      <LinkContainer to="/students/pay">
        <Nav.Link eventKey="pay">Pay applcation fees.</Nav.Link>
      </LinkContainer>
      Applications without the payments will be rejected.
    </p>
    <h5>Send Application Documents</h5>
    <p>
    For simplicity this year, we are requesting you to email the documents.
    <br/>Send Application in Materials in email to &nbsp;
      <a href={"mailto:" + props.emailTo
        + "?subject=" + props.subject
        + "Name"}
        target="_blank">
        {props.emailTo}
      </a>
    <br/>
    <strong>Subject</strong> is:
     Sparksip 21: <strong>FirstName LastName </strong>.
    </p>
    <p>
    In the body of the message state what projects you are applying for.
    <br/>Also include the following as attachments.
    </p>
    <ul>
      <li><strong>Resume</strong>
      &nbsp; See &nbsp;
      <a href="https://blog.sparksip.org/2021/03/resume-preparation">
      Resume Preparation Tips</a>
      </li>
      <li><strong>Current School Transcript</strong> in PDF format.
      <br/><small>Please obtain this from your school office.
        We need the school name as well as student name clearly printed.
        Include ALL your high school grades. Screenshots will not be accepted.
        </small>
      </li>
      <li><strong>Personal Essay for projects</strong> of interest for you.
        See more &nbsp;
        <a href="https://blog.sparksip.org/2021/03/personal-essay-for-sparksip-application/">
        here</a>.
        Yes, <a href="https://blog.sparksip.org/2021/03/yes-essay-is-per-project/">
        each project gets its own essay</a>.
        Kindly please write <strong>questions</strong> when you write the
        essay per project.
      </li>
      <li><strong>SparkSIP Parent Student Consent</strong>
      - signed and scanned
        &nbsp;
        <a href="/docs/2021-03-09-Spark-Parent-Student-Consent-form.pdf">Form</a>
      </li>
    </ul>
    <p>
    Spark SIP volunteers will do their best to try and work with the student
     to make their application better and stronger.
    But sometimes if the student does not respond in a timely manner,
    incomplete applications will be rejected,
    and the application fee will be forfeited.
    </p>
  </>
);

const StudentSendApp = {
  id: "send_app",
  name: "Apply",
  status: "active",

  title: "Send Application",

  bg: "bisque",
  textColor: "dark",
  size: "md",

  imgsrc: "/images/desk_laptop1.svg",
  imgalt:"Send Application",
  imgcols:4,
  imgheight:"240px",

  buttonText: "Send Application",
  buttonColor: "info",
  // turn on buttonPath when we are ready
  // buttonPath:"/business/review",

  messageBody: ( <SendApplicationMaterials
    emailTo={process.env.SPARKSIP_APP_EMAILTO || "spark.sip@outlook.com"}
    subject="Sparksip 21: " // ToDo: inject user's first name and last name
    />)
};

export default StudentSendApp;
