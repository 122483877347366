
const faq_items = [
  {
    question: "How does the SparkSIP process work?",
    id: "student_process",
    answer:[
      "SparkSIP requests organizations to sponsor internship projects.",
      "Students apply for these projects and get matched for an interview. On mutual acceptance, the internships start in the summer."
    ],
    component_id: "TIMELINE"
  },
  {
    question: "When does a student apply for the summer internship?",
    id: "student_process-when",
    answer:[
      "Typically the projects are posted for application in March of each year.",
      "Please apply before Apr 2nd week. Do not wait till the last day!"
    ]
  },
  {
    question: "Can I refer my friend for SparkSIP internship?",
    id: "student_referral",
    answer:[
      "Yes! We would love it. We have grown the network of students with referrals and word of mouth.",
      "Thanks for your contributions to build a stronger community.",
    ],
    link_id: "/friend",
    link_name: "Refer a Friend"
  },
  {
    question: "Where can I find all the projects for this year?",
    id: "student_projects_for_this_year",
    answer:[
      "Glad you asked. You can find all current year projects at https://sparksip.org/projects."
    ],
    link_id: "/projects",
    link_name: "View Projects"
  },
  {
    question: "Are students who will enter 9th grade in Fall 2021 eligible to apply for the projects?",
    id: "student_entering_9th_grade",
    answer:[
      "No. At present SparkSIP projects are open only for the students in grades 9-12.",
      "Students in grades 9-12 for the current school year 2020-2021 are eligible to apply."
    ]
  },
  {
    question: "I am a freshman in college? Can I apply?",
    id: "student_in_college",
    answer:[
      "Congrats that you are in college already. SparkSIP helps students prepare for college applications.",
      "This program is meant to help high school students showcase their passion",
      "Colleges have more streamlined internship programs for their students.",
      "Please check with your Career advising department or one of many internet based internship websites.",
      "Students in college are not eligible to apply.",
    ]
  },
  {
    question: "Do we need to write a different essay for each project that we apply to?",
    id: "student_essay_per_project",
    answer:[
      "Yes, please. We require a student to write essays specific to projects of interest.",
      "Each essay will have answers to the 3 questions.",
      "Your specific essay for a project is forwarded to the provider. ",
      "The provider uses the essay as an important ingredient to determine your fit for the specific internship.",
      "Just think about the question \"Why do you think that you are a good fit for this internship?\"",
      "Don't you think it has to be tailored to the project? How else can you convince the provider that you are the right fit?",
      "Only the content for question#3 on strengths and weakness can perhaps be the same for each project.",
    ],
    link_id: "https://blog.sparksip.org/2021/03/personal-essay-for-sparksip-application/",
    link_name: "More on Essay"
  },
  {
    question: "If we do get matched with a sponsor and project, are we allowed to cancel it in case of scheduling difficulties?",
    id: "student_canceling_after_match",
    answer:[
      "Please think hard about your commitments for the summer before applying. SparkSIP and our business sponsors invest a lot of time processing the applications, interviewing and deciding on selected candidates. Last, but not least, you are taking away the opportunity for another great student.",
      "So we highly recommend that you do not cancel after a match.",
      "However in an emergency or unforeseen situations, you are allowed to cancel. Please state the solid reason for such cancelation.",
      "Otherwise, we will take this into consideration if you apply again. Also, regardless, your fees will not be reimbursed.",
    ]
  },
  {
    question: "What is the chance of getting matched with a sponsor and project?",
    id: "student_matching_chance",
    answer:[
      "We do our best to find great sponsors and projects. You do your best to prepare and apply.",
      "There are no guarantees on matching. We often receive more applications than available positions; It is a very competitive process. The final selection of the candidate for the interview and the internship position is by the internship provider.",
    ]
  },
  {
    question: "Why are you folks at SparkSIP doing this?",
    id: "student_purpose",
    answer:[
      "We came from places where economic opportunities and meaningful work was limited. We learnt from many mentors in apprentices and coaching sessions. We feel that similar opportunities will benefit students and organizations.",
    ]
  },
  {
    question: "Where can I learn more?",
    id: "student_learn-more",
    answer:[
      "Please submit your request in the contact us form below."
    ],
    link_id: "/contact",
    link_name: "Contact Us"
  },

/*

Does the sponsor need to pay the matching service?
No. SparkSIP is run as a non-profit with volunteers handling the sourcing of students and matching of students with internship sponsors without additional cost for the sponsors.

*/
];

export default faq_items;
