import React from "react";
import Section from "./../../components/Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./../../components/SectionHeader";
import SponsorForm from "./SponsorForm";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";
import {ActionContainerSection} from './ActionContainerSection';
import ReferralSection from './ReferralSection';

const SponsorIntroduction = () => (
  <>
  <p>It just takes 10 minutes to sponsor a project.
  <br/>
  For more information on some current and past projects, visit <a href="/projects">Projects</a>.
  <br/>For further information please see the <a href="/faq">FAQ</a> section.
  </p>
  </>
);

function SponsorFormSection(props) {
  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container
        style={{
          maxWidth: "850px",
        }}
      >
        <Row>
          <Col xs={12} lg={3}>
            <img src="/images/stand_front_star1.svg" alt="Sponsor Project" height="150px"/>
          </Col>
          <Col xs={12} lg={9}>
          <SectionHeader
            title={props.title}
            subtitle={props.subtitle}
            size={2}
            spaced={true}
            className="text-center"
          />
            <SponsorIntroduction/>
          </Col>
        </Row>
        <SponsorForm
          showNameField={props.showNameField}
          buttonText={props.buttonText}
          buttonColor={props.buttonColor}
        />
      </Container>
    </Section>
  );
}


const SponsorThanks = () => (
  <>
  <p>Thank You from <b>SparkSIP</b> for submitting a project.
  <br/>
  Your project will soon appear at <a href="/projects">Projects</a> page after a review.
  </p>
  <p>
  Students will apply in March and April.
  You can expect to see a list applicants in late April for the next step.
  </p>
  </>
);

const ThanksForReferral = () => (<h2>Thanks for your referral!</h2>);

const SponsorActions = {

  show_sponsor_form: (props) => (<SponsorFormSection {...props} />),
  thank_sponsor: (props) => (
    <ActionContainerSection messageBody={<SponsorThanks />} {...props} />),
  refer_sponsor: (props) => (
    <ActionContainerSection {...props} onNow={true}
      messageBody={
        <ReferralSection accessFrom="business"
          buttonText={props.buttonText} buttonColor={props.buttonColor}
          bizinfo= {{
            logo: process.env.REACT_APP_LOGO,
            bizname: process.env.REACT_APP_NAME
          }}
          message="Please join SparkSIP at https://sparksip.org."
          subscribedMessage=<ThanksForReferral/>
          />}
    />),
}

export default SponsorActions;
