import GoogleSheet from './../../providers/googlesheet';
import { DbObject } from "./../../util/db";
const NewsletterEntryObject = new DbObject( "newsletter", false);

class NewsletterEntry {
  constructor( data) {
    this.email = data.email;
    this.location = data.location;
    this.highschool = data.highschool;
    this.interests = data.interests;
    this.accessFrom = data.accessFrom;
  }

  print() {
    console.log("\n ZNewsletter");
    console.log( `Email: ${this.email}`);
    console.log( `Location: ${this.location}`);
    console.log( `High School: ${this.highschool}`);
    console.log( `Interests: ${this.interests}`);
    console.log( `Access From: ${this.accessFrom}`);
  }

  asArray() {
    return [
      this.email,
      this.location,
      this.highschool,
      this.interests,
      this.accessFrom,
    ]
  }

}

function addToNewsletter( newsletterEntry) {

  // console.log( `About to submit entry to newsletter. ${JSON.stringify(newsletterEntry)}`);
  // For now we record the data in both Google Sheet and Firestore

  // 1. Submit to GoogleSheet
  GoogleSheet
    .submit(
      process.env.REACT_APP_SHEETS_ENDPOINT,
      process.env.REACT_APP_NEWSLETTER_TAB_ID,
      newsletterEntry.asArray());

  // 2. add to the Firesetore database
  return NewsletterEntryObject.CreateObject( newsletterEntry);

}

const NewsletterService = {
  addToNewsletter: addToNewsletter
};

export default NewsletterService;
export { NewsletterEntry};

// use for imports
// import NewsletterService, { NewsletterEntry} from "./../util/newsletter.js";
