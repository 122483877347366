import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { LinkContainer } from "react-router-bootstrap";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Dropdown from "react-bootstrap/Dropdown";
import { useAuth } from "./../util/auth.js";
import './NavbarCustom.scss';

const NavUserPart = ( {auth} ) => (
  <Nav>
  {/*
    auth.user is:
{ "uid":""
"email":"",
"emailVerified":true | false,
"name":"",
"picture":null,
"providers":[""],
"id":""}
  */}
    {auth.user && (
      <NavDropdown id="dropdown"
        title={auth.user.name ? auth.user.name : "Account"} alignRight={true}>
        <LinkContainer to="/dashboard">
          <NavDropdown.Item active={false}>Dashboard</NavDropdown.Item>
        </LinkContainer>

        <LinkContainer to="/settings/general">
          <NavDropdown.Item active={false}>Settings</NavDropdown.Item>
        </LinkContainer>

        <Dropdown.Divider />

        <LinkContainer to="/auth/signout">
          <NavDropdown.Item
            active={false}
            onClick={(e) => {
              e.preventDefault();
              auth.signout();
            }}
          >
            Sign out
          </NavDropdown.Item>
        </LinkContainer>
      </NavDropdown>
    )}

    {!auth.user && (
      <Nav.Item>
        <LinkContainer to="/auth/signin">
          <Nav.Link active={false}>Sign in</Nav.Link>
        </LinkContainer>
      </Nav.Item>
    )}
  </Nav>
);

function NavbarCustom(props) {
  const auth = useAuth();

  return (
    <Navbar className="NavbarCustomClass" variant={props.variant}
      expand={props.expand}>
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand>
            <img
              className="d-inline-block align-top"
              src={props.logo}
              alt="Logo"
              height="30"
            />
          </Navbar.Brand>
        </LinkContainer>

        <Navbar.Toggle aria-controls="navbar-nav" className="border-0" />
        <Navbar.Collapse id="navbar-nav" className="justify-content-end">
          <Nav>
            <Nav.Item>
              <LinkContainer to="/">
                <Nav.Link active={false}>Home</Nav.Link>
              </LinkContainer>
            </Nav.Item>
            <Nav.Item>
              <LinkContainer to="/business">
                <Nav.Link active={false}>Business</Nav.Link>
              </LinkContainer>
            </Nav.Item>
            <Nav.Item>
              <LinkContainer to="/students">
                <Nav.Link active={false}>Students</Nav.Link>
              </LinkContainer>
            </Nav.Item>
          </Nav>

          <NavUserPart auth={auth}/>

        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarCustom;
