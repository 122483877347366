import GoogleSheet from '../providers/googlesheet';

function submit_contacts(data) {
  return GoogleSheet
    .submit(
      process.env.REACT_APP_SHEETS_ENDPOINT,
      process.env.REACT_APP_SHEETS_CONTACTS_TAB_ID,
      [data.name, data.email, data.message]);
}

function submit(data) {
  return submit_contacts(data);
}

export default { submit };
