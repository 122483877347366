import { useReducer, useEffect, useRef } from "react";
import { firestore, serverTimestamp, useQuery } from "./db_helpers";
import DbObject from './db_object';

// ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- -----
/**** USERS ****/
// ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- -----

// Fetch user data (hook)
// This is called automatically by auth.js and merged into auth.user
export function useUser(uid) {
  return useQuery(uid && firestore.collection("users").doc(uid));
}

// Update an existing user
export function updateUser(uid, data) {
  return firestore.collection("users").doc(uid).update(data);
}

// Create a new user
export function createUser(uid, data) {
  return firestore
    .collection("users")
    .doc(uid)
    .set({ uid, ...data }, { merge: true });
}

// ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- -----
/**** ITEMS ****/
// ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- -----

/* Example query functions (modify to your needs) */

// Fetch all items by owner (hook)
export function useItemsByOwner(owner) {
  return useQuery(
    owner &&
      firestore
        .collection("items")
        .where("owner", "==", owner)
//      for now the items do not have a createAt timestamp
//        .orderBy("createdAt", "desc")
  );
}

// Fetch item data
export function useItem(id) {
  return useQuery(id && firestore.collection("items").doc(id));
}

// Update an item
export function updateItem(id, data) {
  return firestore.collection("items").doc(id).update(data);
}

// Create a new item
export function createItem(data) {
  return firestore.collection("items").add({
    ...data,
    createdAt: serverTimestamp(),
  });
}

// Delete an item
export function deleteItem(id) {
  return firestore.collection("items").doc(id).delete();
}



// ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- -----
/**** additional objects stored in Firestore ****/
// ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- -----
// ToDo: how to create singleton or static object?
const StudentReferralDbObject = new DbObject( "student_referrals", false);

export { StudentReferralDbObject, DbObject};
