import React from "react";
import DashboardSection from "./../components/DashboardSection";
import { requireAuth } from "./../../util/auth.js";

function SparksipDashboardPage(props) {
  return (
    <DashboardSection
      bg="white"
      textColor="dark"
      size="md"
      bgImage=""
      bgImageOpacity={1}
      /* title="Dashboard"
      subtitle="More details coming soon." */
    />
  );
}

export default requireAuth(SparksipDashboardPage);
