import React from "react";
import FeaturesSection from './../components/FeaturesSection';

function NotFoundPage(props) {
  return (
    <div
      style={{
        padding: "50px",
        width: "100%",
        textAlign: "center",
      }}
    >

      <FeaturesSection
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
/*      title="Stay Tuned"
        subtitle="We are working on 2021 internships. Thanks for your interest!" */
      />
    </div>
  );
}

/*
We are busy preparing this section at <code>{props.location.pathname}</code>.
Please check back with us soon.
*/

export default NotFoundPage;
