
import feature_items from './feature_items';
import actions_for_timeline from './timeline.js';
import team_bios from './team_bios';
import testimonials from './testimonials';
import stats_data from './stats_data';
import faq_business_items from './faq_business_items';
import faq_student_items from './faq_student_items';
import featured_business from './featured_business';
import { itemsForSponsors, itemsForStudents} from './pricing_plans';
import map_of_communities from './communities';

const currentDate = new Date();
const appDeadlineDate = new Date( process.env.REACT_APP_DEADLINE_DATE);

const APP_FLAGS = {
  enable_purchase: true,
  purchase_prod_test: false,
  show_site_url : false,
  appsDeadlineReached: (currentDate > appDeadlineDate),
}

const AppConfigs = {
    features: feature_items,
    actions_for_timeline: actions_for_timeline,
    team_bios: team_bios,
    testimonials: testimonials,
    stats_data: stats_data,
    faq_student_items: faq_student_items,
    faq_business_items: faq_business_items,
    featured_business: featured_business,
    itemsForSponsors: itemsForSponsors,
    itemsForStudents: itemsForStudents,
    map_of_communities: map_of_communities,
    flags: APP_FLAGS,
};

export default AppConfigs;
