const testimonials = [
  {
    name: "Will, CEO",
    testimonial:
    "For four years in a row, I have repeatedly sponsored projects. Cartogram has found exceptional, hard-working high school interns as a result of the program. We will continue to keep coming back again and again to sponsor. ",
    company: "Cartogram",
  },
  {
    name: "UW Professor",
    testimonial:
      "SPARK provides a great way for students to get real-life experience in a research lab. I am pretty impressed with the quality of students I got in my lab.",
    company: "University of Washington",
  },
  {
    name: "DL, Past SparkSIP Intern",
    testimonial:
      "As a STEM enthusiast, I learnt a lot from the electronics lab I worked at for the summer. My mentor was an excellent coach and helped me to learn from real world settings. Thanks to SparkSIP, I found great internship and understanding of real world projects.",
    company: "Junior at local High School",
  },

  {
    //Quote came from Riya Baheti, SparkSIP Intern in 2019 Summer; she was graduating senior then.
    name: "RB, past SparkSIP Intern",
    testimonial:
      "SparkSIP was a great internship experience for me to transition between high school and college. It built on the skill levels I already had but also challenged me and prepared me for more difficult class and the recruiting process in college!",
    company: "Senior at Redmond High School",
  },

];

export default testimonials;
