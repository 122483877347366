import React from "react";
import Section from "../../components/Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "../../components/SectionHeader";
import PagingNavContainer from "./PagingNavContainer";
import StudentActions from "./../components/StudentActions.ALL";
import RenderMultipleSections, { ActionContainerSection } from './ActionContainerSection';

const SECTIONS_FOR_AUTHENTICATED_NAV = [
    StudentActions.prepare,
    StudentActions.profile,
    StudentActions.projects,
    StudentActions.pay,
    StudentActions.send_app,
    // StudentActions.submit_app,
    StudentActions.refer,
];

const AUTHENTICATED_STUDENT_NAV_ITEMS = SECTIONS_FOR_AUTHENTICATED_NAV.map( (sa, i) => {
  return { id: sa.id, url: "/students/"+ sa.id, name: sa.name, status: sa.status, detail: sa};
});

// ToDo: information on how to submit the projects,
//  eligibility categories and virtual opportunities.
// for now we point users to the prepare section
const DEFAULT_STUDENT_SECTION = "prepare";

function StudentDashboardSection(props)  {
  let currentSection = StudentActions[props.section_id];
  if (!currentSection) {
    currentSection = StudentActions[DEFAULT_STUDENT_SECTION];
  }

  return (
    <StudentDashboardMultipleSections
      {...props}
      title="For Students"
      navitems={AUTHENTICATED_STUDENT_NAV_ITEMS}
      section_id={props.section_id}
      sequenceOfSections={[
        currentSection
       ]}
      />
  );
}

function StudentDashboardMultipleSections(props) {

  const styleAttributes = {
    bg: "white",
    textColor: "dark",
    size: "md",
    bgImage: "",
    bgImageOpacity: 1,
    headerLocation: "right",  // default position is on the right
  };

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
      <SectionHeader
        title={props.title}
        subtitle={props.subtitle}
        size={2}
        spaced={true}
        className="text-center"
      />
        {props.navitems && (
          <PagingNavContainer className="mb-1"
            navitems={props.navitems}
            section_id={props.section_id}
            />
          )}

        <RenderMultipleSections sections={props.sequenceOfSections} {...props}/>

      </Container>
    </Section>
  );
}

// later enable auth model
// export default requireAuth(StudentPage);

export default StudentDashboardSection;
export { StudentDashboardMultipleSections };
