import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./SectionHeader";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";
import { formatToParas } from './FormatHelpers.js';
import AppConfigs from '../configs';

import "./FeaturesSection.scss";
import RenderComponent from './../my/components/my_components';

function FeaturesSection(props) {
  const items = AppConfigs.features;

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className="text-center">
        { props.title &&
          <SectionHeader
            title={props.title}
            subtitle={props.subtitle}
            size={1}
            spaced={false}
          />
        }
        <Card>
          <Row className="no-gutters overflow-hidden">
            <RenderComponent component_id="TIMELINE"/>

            {items.map((item, index) => (
              <Col
                xs={12}
                lg={4}
                style={{
                  display: "flex",
                  alignItems: "stretch",
                  justifyContent: "center",
                  boxShadow: "1px 1px 0 0 #efefef",
                }}
                className={item.status==="active" ? "FeaturesSection__active" : "FeaturesSection__inactive"}
                key={index}
              >
                <div className="FeaturesSection__item has-text-centered">
                  <h3>{item.title}</h3>
                  <div className="FeaturesSection__image-container">
                      <Image src={item.image} alt={item.title} fluid={true} height="80px"/>
                  </div>
                  <div className="FeaturesSection__item_content text-left">
                    {formatToParas(item.body)}
                  </div>
                  <LinkContainer to={item.to}>
                    <Button variant={item.actionStyle? item.actionStyle : "warning"} size="lg">
                      {item.actionTitle}
                    </Button>
                  </LinkContainer>
                </div>
              </Col>
            ))}
          </Row>
        </Card>
      </Container>
    </Section>
  );
}

export default FeaturesSection;
