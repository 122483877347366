// student.js - landing page for the student users

import React from "react";
import { DeadlineAlertBanner, NewProjectsAlertBanner } from "./../components/AlertBanners";
import StudentDashboardSection
  from './../components/StudentDashboardSection';
import { useRouter } from "./../../util/router.js";
import { requireAuth } from "./../../util/auth.js";

const StudentPage = (props) => {
  const router = useRouter();

  return (
    <>
    <DeadlineAlertBanner />
    <NewProjectsAlertBanner />
    <StudentDashboardSection
      {...props}
      title="For Students"
      section_id={router.query.section_id}
      />
    </>
  );
}

export default requireAuth(StudentPage);
