import React from "react";

const InterviewSelection = () => (
  <>
  <p>
    In May, The internship sponsor will assess the short-listed candidates
      against requirements and may call one or all the applicants
      for virtual interviews.
    It is recommended that applications make themselves available
      for sufficient flexibility to be able to attend these interviews.
  </p><p>
  Spark SIP will inform applicants of their selection or rejection in May.
  At the end of the process all selected candidates
    will be informed of their selections. If there are suitable positions open,
    then SparkSIP may match suitable students for still open positions.
  </p>
  </>
);

const StudentInterview = {
  id: "interview",
  name: "Interview",
  status: "not-ready",
  title: "Interview and Selection",

  imgsrc: "/images/stand_back_point2.svg",
  imgalt: "Interview and Selection",
  imgcols: 4,
  imgheight:"300px",

  buttonText: "Interview and Selection",
  buttonColor: "info",
  // turn on buttonPath when we are ready
  // buttonPath:"/business/select",

  messageBody: (<InterviewSelection />)
};

export default StudentInterview;
