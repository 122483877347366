
import React, { useState } from "react";
import FormAlert from "./../../components/FormAlert";
import Form from "react-bootstrap/Form";
import FormField, {FormSelect} from "./../../components/FormField";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Col from "react-bootstrap/Col";
import Project from "./../objects/project.js";
import Business from "./../util/business.js";
import { history } from "./../../util/router";
import { useAuth } from "./../../util/auth.js";
import { useForm } from "react-hook-form";


function sendThankyou( result) {
  // console.log( `Result from submission: ${JSON.stringify(result)}`);

  history.replace("/business/sponsor/thankyou");
}

function sayThanksInline( setFormAlert) {
  // Show success alert message
  setFormAlert({
    type: "success",
    message: "Thanks for sponsoring a project. We will be in touch soon.",
  });
}

function SponsorForm(props) {
  const [pending, setPending] = useState(false);
  const [formAlert, setFormAlert] = useState(null);
  const { handleSubmit, register, errors, reset } = useForm();
  const auth = useAuth();

  const onSubmit = (data) => {
    // Show pending indicator
    setPending(true);
    const project = new Project( data);

    Business
      .addProject(project)
      .then(( result) => {
        // Clear form
        reset();

        sendThankyou( result);
        // for testing purpose: sayThanksInline( setFormAlert);
      })
      .catch((error) => {
        // Show error alert message
        setFormAlert({
          type: "error",
          message: error.message,
        });
      })
      .finally(() => {
        // Hide pending indicator
        setPending(false);
      });
  };

  return (
    <>
      {formAlert && (
        <FormAlert type={formAlert.type} message={formAlert.message} />
      )}

      <Form onSubmit={handleSubmit(onSubmit)}>
        <hr/>
        <h3>Project Sponsor Information</h3>

        <Form.Row>
          {props.showNameField && (
            <Form.Group as={Col} xs={12} sm={6} controlId="formName">
              <FormField
                size="lg"
                label="Name of primary contact"
                name="name"
                type="text"
                placeholder="Name"
                defaultValue={auth.user ? auth.user.name : ""}
                error={errors.name}
                inputRef={register({
                  required: "Please enter a name",
                })}
              />
            </Form.Group>
          )}

          <Form.Group as={Col} xs={12} sm={props.showNameField ? 6 : 12}
          controlId="formEmail"
          >
            <FormField
              size="lg"
              label="Email for primary contact"
              name="email"
              type="email"
              placeholder="Email"
              defaultValue={auth.user ? auth.user.email : ""}
              error={errors.email}
              inputRef={register({
                required: "Please enter an email",
              })}
            />
          </Form.Group>

          <Form.Group as={Col} xs={12} sm={6}
          controlId="formCompany"
          >
            <FormField
              size="lg"
              label="Name of your organization"
              name="organization"
              type="text"
              placeholder="Company"
              error={errors.organization}
              inputRef={register({
                required: "Please enter the name of your Organization",
              })}
            />
          </Form.Group>
          <Form.Group as={Col} xs={12} sm={6}
          controlId="formSiteUrl"
          >
            <FormField
              size="lg"
              label="Website for your organization"
              name="siteurl"
              type="text"
              placeholder="Site URL"
              error={errors.siteurl}
              inputRef={register({
                // required: "Please enter your organization's website",
              })}
            />
          </Form.Group>
          <Form.Group as={Col} xs={12} sm={6}
          controlId="formLocation"
          >
            <FormField
              size="lg"
              label="Location of the internship"
              name="location"
              type="text"
              placeholder="Street Address, City, State ZIPCODE"
              error={errors.location}
              inputRef={register({
                // required: "Please enter your organization's website",
              })}
            />
          </Form.Group>
          <Form.Group as={Col} xs={12} sm={6}
          controlId="formRemote"
          >
            <FormSelect
              size="lg"
              label="Do you support remote internship? "
              name="has_remote"
              type="select"
              placeholder="Yes"
              select_options={[ "Yes", "Maybe", "No"]}
              error={errors.has_remote}
              inputRef={register({
                // required: "Please enter your organization's website",
              })}
            />
          </Form.Group>
        </Form.Row>

        <hr/>
        <h3>Describe the Internship Project</h3>

        <Form.Group controlId="formProjectTitle">
          <FormField
            size="lg"
            label="Short Title for your Project"
            name="project_title"
            type="text"
            placeholder="Project Title"
            error={errors.project_title}
            inputRef={register({
              required: "What is the short title for your project?",
            })}
          />
        </Form.Group>

        <Form.Row>
          <Form.Group as={Col} xs={12} sm={6}
          controlId="formNumWeeks"
          >
            <FormField
              size="lg"
              label="Number of Weeks (in summer)"
              name="numweeks"
              type="text"
              placeholder="Number of Weeks"
              error={errors.numweeks}
              inputRef={register({
                required: "Length of Internship (in weeks) [4, 6, 8, 10, 12]"
              })}
            />
          </Form.Group>
          <Form.Group as={Col} xs={12} sm={6}
          controlId="formNumHours"
          >
            <FormField
              size="lg"
              label="Typical Number of hours per week"
              name="hours_per_weeek"
              type="text"
              placeholder="# hours per week"
              error={errors.hours_per_weeek}
              inputRef={register({
                required: "Number of hours per week",
              })}
            />
          </Form.Group>
        </Form.Row>
        <Form.Group controlId="formDescription">
          <FormField
            label="Describe your project (3-6 sentences)"
            size="lg"
            name="description"
            placeholder="Description"
            type="textarea"
            rows={6}
            error={errors.description}
            inputRef={register({
              required: "Describe your project (3-6 sentences)",
            })}
          />
        </Form.Group>

        <Form.Group controlId="formQualifications">
          <FormField
            label="Qualifications you are looking for (2-5 sentences)"
            size="lg"
            name="qualifications"
            type="textarea"
            placeholder="Qualifications"
            rows={4}
            error={errors.qualifications}
            inputRef={register({
              required: "Qualifications you are looking for (2-5 sentences)",
            })}
          />
        </Form.Group>

        <h3>Research Section (optional)</h3>

        <Form.Group controlId="formHasResearch">
          <FormSelect
            size="lg"
            label="Is there a Scientific or Business Research component?"
            name="has_research"
            type="select"
            placeholder="No"
            select_options={[ "No", "Maybe", "Yes"]}
            error={errors.has_research}
            inputRef={register({
              // required: "Please enter your organization's website",
            })}
          />
        </Form.Group>

        <Form.Group controlId="formResearch">
          <FormField
            size="lg"
            label="Describe the Research Component (3-5 sentences)"
            name="research"
            type="textarea"
            placeholder="Research may attract more STEM oriented students"
            rows={4}
            error={errors.research}
            inputRef={register({
              // required: "Describe the Research Component (3-5 sentences)",
            })}
          />
        </Form.Group>

        <h3>Stipend (optional)</h3>
        <p>Students learn from real world projects under guidance.
        At most if feasible we recommend that the businesses provide a maximum of
        $500 stipend for the entire period. You and the student can agree on the terms
        when you meet for interview or at the offer match stage.
        </p>

        <Form.Row>
          <Form.Group as={Col} xs={12} sm={6}
          controlId="formRemote"
          >
            <FormSelect
              size="lg"
              label="Do you support a nominal stipend? "
              name="has_stipend"
              type="select"
              placeholder="Yes"
              select_options={[ "Yes", "No"]}
              error={errors.has_stipend}
              inputRef={register({
                // required: "Please enter your organization's website",
              })}
            />
          </Form.Group>
        </Form.Row>

        <Button
          variant={props.buttonColor}
          size="lg"
          type="submit"
          disabled={pending}
        >
          <span>{props.buttonText}</span>

          {pending && (
            <Spinner
              animation="border"
              size="sm"
              role="status"
              aria-hidden={true}
              className="ml-2"
            >
              <span className="sr-only">Sending...</span>
            </Spinner>
          )}
        </Button>
      </Form>
      {formAlert && (
        <FormAlert type={formAlert.type} message={formAlert.message} />
      )}
    </>
  );
}

export default SponsorForm;
