import React from "react";
import SponsorActions from "./../components/SponsorSection";
// import { requireAuth } from "./../util/auth.js";


function SponsorThanksPage(props) {

  return (
    <>
      <SponsorActions.thank_sponsor
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}

        title="Thanks for sponsoring a project"
        subtitle=""

        imgsrc="/images/ThankYou.svg"
        imgalt="Thank You!"

        buttonPath="/business/sponsor"
        buttonText="Submit Another Project"
        buttonColor="success"
        showNameField={true}
      />
      <SponsorActions.refer_sponsor
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}

        title="Refer your Friend"
        subtitle=""

        imgsrc="/images/ReferAFriend.svg"
        imgalt="Refer A Friend"
        imgcols={4}
        imgheight="160px" // ToDO: need to size this up based on screen view

        buttonText="Refer a friend"
        buttonColor="success"
        showNameField={true}
      />
    </>
  );

}

// export default requireAuth(SponsorThanksPage);
export default SponsorThanksPage;
