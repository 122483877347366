import React from "react";
import Section from "./../../components/Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./../../components/SectionHeader";
import SponsorForm from "./SponsorForm";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";

function ActionContainerSection(props) {

  const imgcols = props.imgcols ? props.imgcols : 0;
  const imgheight= props.imgheight ? props.imgheight : "240px"
  const onNow = (props.onNow || props.status === 'active' || props.buttonPath !== undefined );

  const SectionHeaderInfo = () => (
    <SectionHeader
      title={(onNow ? '': 'Coming Soon: ') + props.title}
      subtitle={props.subtitle}
      size={3}
      spaced={true}
      className="text-center"
    />
  );

  return (
    <Section
      bg={onNow ? props.bg : "silver"}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <Row>
          {(props.imgcols > 0) && (
            <Col xs={12} lg={imgcols}>
              {(props.headerLocation === "left") && (props.title && <SectionHeaderInfo />)}
              <img src={props.imgsrc} alt={props.imgalt} height={imgheight} max-width="100%"/>
            </Col>
          )}
          <Col xs={12} lg={12-imgcols}>
            {(props.headerLocation === "right") &&  (props.title && <SectionHeaderInfo />) }

            {props.messageBody &&
              <div>{props.messageBody}</div>}

            {props.buttonPath &&
              <Row className="justify-content-center">
              <LinkContainer to={props.buttonPath}>
                <Button variant={onNow ? props.buttonColor: "info"}
                  disabled={!onNow} size="lg">
                  {props.buttonText}
                </Button>
              </LinkContainer>
              </Row>
            }
          </Col>
        </Row>
      </Container>
    </Section>
  );
}


function RenderMultipleSections(props) {

  const styleAttributes = {
    bg: "white",
    textColor: "dark",
    size: "md",
    bgImage: "",
    bgImageOpacity: 1,
    headerLocation: "right",  // default position is on the right
  };

  const firstTitle = props.firstTitle || "Business";

  return (
    <>
      { props.sections.map( (section, i) => (
        <ActionContainerSection key={i}
          title={firstTitle}
          {...styleAttributes}
          {...section}
          />
      ))}
    </>
  );
}

/*
 usage is:
  <ActionContainerSection {...props}
      messageBody={<ActionContentComponent buttonText={props.buttonText} buttonColor={props.buttonColor}/>

  <RenderMultipleSections firstTitle={title text} sections={sectionsArray} {...props}/>)
*/

export default RenderMultipleSections;
export { ActionContainerSection};
