/* db_object.js - database access for general object that is specified at init */
import { useReducer, useEffect, useRef } from "react";
import { firestore, serverTimestamp, useQuery } from "./db_helpers";

// ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- -----
/**** db_object class ****/
// ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- ----- -----

function DbObject( objectName, fVerbose = false ) {

  // return a hook for the fetch of the object
  function GetObjectByUser( owner) {
    if (fVerbose) {
      console.log( `Fetching ${objectName} from firestore`);
    }

    return useQuery(
      owner &&
        firestore
          .collection( objectName)
          .where("owner", "==", owner)
    );
  }

  // Fetch item data
  function UseObject(id) {
    if (fVerbose) {
      console.log( `Get Object ${objectName} with id=${id}`);
    }
    return useQuery(id && firestore.collection( objectName).doc(id));
  }

  // Update an item
  function UpdateObject(id, data) {
    if (fVerbose) {
      console.log( `Update Object ${objectName} with id=${id} with data:`);
      console.log( data);
    }
    return firestore.collection( objectName).doc(id).update(data);
  }

  function SetObject(id, data) {
    if (fVerbose) {
      console.log( `Set Object ${objectName} with id=${id} with data:`);
      console.log( data);
    }
    return firestore.collection( objectName).doc(id).set(data);
  }

  // Create a new item
  function CreateObject(data) {
    if (fVerbose) {
      console.log( `Create Object ${objectName} with data:`);
      console.log( data);
      console.log( JSON.stringify(data));
    }
    return firestore.collection( objectName).add({
      ...data,
      createdAt: serverTimestamp(),
    });
  }

  // Delete an item
  function DeleteObject(id) {
    if (fVerbose) {
      console.log( `Delete Object ${objectName} with id=${id}`);
    }

    return firestore.collection( objectName).doc(id).delete();
  }

  return { CreateObject, GetObjectByUser, UseObject, UpdateObject, SetObject, DeleteObject}
}

export default DbObject;
