
const itemsForSponsors = [
  {
    id: "starter",
    name: "Pay It Forward (Starter)",
    price: "0",
    perks: [
      "ZERO cost to post sponsored projects.",
      "Post up to 2 sponsored projects.",
      "Receive and Review up to 5 candidates for your sponsored projects.",
      "Email / online support.",
    ],
  },
  {
    id: "pro",
    name: "Sponsor Magnet (Pro)",
    price: "20",
    perks: [
      "Post up to 5 sponsored projects.",
      "Receive and Review up to 10 candidates for your sponsored projects.",
      "Get candidate feedback after interview and after internship",
      "Email / online support.",
    ],
  },
  {
    id: "business",
    name: "Sponsor Exemplar (Leader)",
    price: "199",
    perks: [
      "Post up to 20 sponsored projects.",
      "Receive and Review up to 50 candidates for your sponsored projects.",
      "Get candidate feedback after interview and after internship",
      "Email / online / phone support.",
    ],
  },
];

/*
ALL plans to have
+ 20% off if submitted before April 1st
+ financial aid/waiver with suitable documentation

Lastly - I have modified it a little. I have added one extra item with each option below.
Basic - $25
Browse all opportunities
Apply for up to 2 projects
Apply for only local projects (no remote option)

Enhanced - $35
Browse all opportunities
Apply for up to 5 projects
Apply for Remote projects
Admission to Application Preparation workshop (over remote session)

Premium - $50
Browse all opportunities
Apply to unlimited projects*   (NOTE: applicant information will only be sent up to 10 different sponsors)
Apply for Remote projects
Admission to Application Preparation group workshop (over remote session)
*/

// end of day Apr 2nd in Pacific Time Zone is: 2021-04-03T08:00:00.000Z
const DISCOUNT_PERCENTAGE = "0";
const DISCOUNT_DEADLINE = "2021-04-03T00:00:00.000Z";

const itemsForStudents = [
  {
    id: "student_quickstart",
    name: "Quick Start",
    price: "25",
    num_projects: 2,
    discount_percentage: DISCOUNT_PERCENTAGE,
    discount_deadline: DISCOUNT_DEADLINE,
    description: "Quick Start to apply for up to 2 projects.",
    perks: [
      "Browse all sponsored projects.",
      "Apply to up to 2 projects.",
      "Apply for local projects",
      "Apply for remote projects",
    ],
  },
/*
  3/18/2021 - Decision: Simplify pricing and hence remove the "Seek More"  option
  {
    id: "student_seekmore",
    name: "Seek More",
    price: "40",
    num_projects: 4,
    discount_percentage: DISCOUNT_PERCENTAGE,
    discount_deadline: DISCOUNT_DEADLINE,
    description: "Expand your reach to apply for up to 4 projects including remote ones.",
    perks: [
      "Browse all sponsored projects.",
      "Apply to up to 4 projects.",
      "Apply for local projects",
      "Apply for remote projects",
    ],
  },
*/
  {
    id: "student_allin",
    name: "All In Access",
    price: "60",
    discount_percentage: "20",
    discount_deadline: DISCOUNT_DEADLINE,
    description: "Apply to many projects.",    
//    description: "Apply to many projects. Join the Online workshop.",
    num_projects: 100,
    perks: [
      "Browse all sponsored projects.",
      "Apply to unlimited projects.",
      "Apply for local projects",
      "Apply for remote projects",
//      "Online Application Preparation workshop on Apr 3, 2021",
    ],
  },
];

export { itemsForSponsors, itemsForStudents};
