import React, { useState } from "react";
import Section from "./../../components/Section";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SectionHeader from "./../../components/SectionHeader";
import NewsletterForm from "./Newsletter";
import StudentProfileFormFields from "./StudentProfileForm";
import Spinner from "react-bootstrap/Spinner";
import StudentProfileService, { StudentProfileEntry} from "./../util/student_profile.js";
import { useForm } from "react-hook-form";
import { useAuth } from "./../../util/auth.js";
import { useRouter } from "./../../util/router.js";
import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";
import { history } from "./../../util/router";

const StudentProfileAlreadyExistsSection = ( props) => (
  <div>{props.subscribedMessage}
  </div>
);

function StudentProfileSection(props) {

  // Fetch items by owner
  // Returned status value will be "idle" if we're waiting on
  // the uid value or "loading" if the query is executing.
  const auth = useAuth();
  const user = auth.user;  // ensure we store the profile for the user
  const uid = user ? user.uid : undefined;
  const { data: items, error: errorInfo, status } = StudentProfileService.get(uid);
  const router = useRouter();
  var profileExists = (router.query.edit && (router.query.edit === '1')) ? false
    :((status === "success") && (items !== null) &&
      (items.first_name !== undefined || items.last_name !== undefined ));
  const [subscribed, setSubscribed] = useState(false);


  const onSubscribed = ( {first_name, last_name }) => {
    setSubscribed(true);
    return auth
      .updateProfile( {name: first_name + " " + last_name})
      .then(() => {
        // Set success status
        console.log( `Updating the name of user: ${first_name} ${last_name}`);
        history.replace( "/students/profile");
      })
      .catch((error) => {
        if (error.code === "auth/requires-recent-login") {
          console.log( `Error: Updating the name: ${first_name} ${last_name} failed. Requires recent login`);
        } else {
          // Set error status
          console.log( `Error: Updating the name: ${first_name} ${last_name} failed. `,
            error.message);
        }
      });
  }

  return (
    <Row>
      <Col xs={10} sm={8} className="offset-sm-2 offset-xs-1">
        {(status === "idle" || status === "loading") &&
          <Spinner
            animation="border"
            size="sm"
            role="status"
            aria-hidden={true}
            className="align-baseline text-primary"
          >
            <span className="sr-only">Looking for any prior user profile data...</span>
          </Spinner>
        }

        {(status === "error" || status === "success") &&
          (<>
            {profileExists === true &&
              <>
              { subscribed
                ?(<StudentProfileAlreadyExistsSection {...props} items={items}/>)
                :(<p>Your profile is already saved. &nbsp;
                  <LinkContainer to="/students/profile?edit=1">
                    <Nav.Link eventKey="profile">Click to edit your Profile</Nav.Link>
                  </LinkContainer>
                  </p>)
              }
              </>
            }

            {profileExists === false &&
              <>
              <h3>Please set up or update your full profile.</h3>
              <StudentProfileFormFields
                user={user}
                items={items}
                onSubscribed={onSubscribed}
                {...props}/>
              </>}
            </>)}
      </Col>
    </Row>
  );
}

export default StudentProfileSection;
