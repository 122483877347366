// student_unauthenticated.js - unauthenticated pages for student users

import React from "react";
import { DeadlineAlertBanner, NewProjectsAlertBanner,
  ApplicationClosedAlertBanner } from "./../components/AlertBanners";
import StudentActions from "./../components/StudentActions.ALL";
import {StudentDashboardMultipleSections}
  from './../components/StudentDashboardSection';

const StudentApplicationClosedPage = (props) => {

  return (
    <ApplicationClosedAlertBanner />
  );
}

const StudentPreparePage = (props) => {

  return (
    <>
    <DeadlineAlertBanner />
    <NewProjectsAlertBanner/>
    <StudentDashboardMultipleSections
      {...props}
      title="For Students"
      showNav={false}
      sequenceOfSections={[
        StudentActions.prepare,
       ]}
      />
    </>
  );
}

const StudentReferralPage = (props) => {

  return (
    <StudentDashboardMultipleSections
      {...props}
      title="Refer Your Friend"
      showNav={false}
      sequenceOfSections={[
        StudentActions.refer
       ]}
      />
  );
}


const StudentPreregisterPage = (props) => {

  return (
    <StudentDashboardMultipleSections
      {...props}
      title="Preregister your information"
      showNav={false}
      sequenceOfSections={[
        StudentActions.preregister,
        StudentActions.prepare_learn,
       ]}
      />
  );
}

export { StudentPreparePage, StudentReferralPage,
  StudentPreregisterPage,
  StudentApplicationClosedPage };
