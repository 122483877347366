import React from "react";
import NewsletterForm from "./Newsletter";

const SubscribedMessage = () => ( <h2>You are now subscribed!</h2>);

const StudentPreregister = {
  id: "preregister",
  name: "Pre-register",
  status: "active",

  title: "Pre-register Your Interests",
  subtitle: "Get ready for the Summer 2021 project applications.",

  bg: "bisque",
  textColor: "dark",
  size: "md",
  headerLocation: "left",

  imgsrc: "/images/sit_read.svg",
  imgalt: "Preregister your interests",
  imgcols: 6,
  imgheight:"240px",

  buttonText:"Subscribe",
  buttonColor:"success",

  showNameField: true,
  messageBody: (   <NewsletterForm
      buttonText="Subscribe"
      buttonColor="success"
      accessFrom="student"
      inputPlaceholder="Enter your email"
      subscribedMessage=<SubscribedMessage />
    />)
};

export default StudentPreregister;
