import React from "react";
import HeroSection2 from "./../../components/HeroSection2";
import { ProjectDetailSection } from "./../components/ProjectsSection";
import { useRouter } from "./../../util/router.js";
import AppConfigs from '../../configs';
import ProjectsList from './../cache/projects';

function ProjectDetailPage(props) {
  const router = useRouter();
  const project = ProjectsList.filter( (item) => item.id === router.query.project_id);

  return (
    <>
  {/* ToDo: Include filters and slicers */ }
      <ProjectDetailSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Project Detail"
        subtitle=""
        projects={project}
        detail={true}
      />
    </>
  );
}

export default ProjectDetailPage;
