import React from "react";
import ReferralSection from './ReferralSection';

const ThanksForReferral = () => (<h2>Thanks for your referral!</h2>);

const StudentReferral = {
  id: "refer",
  name: "Refer",
  status: "active",
  title: "Refer your Friend",

  bg: "celeste",
  textColor: "dark",
  size: "md",
  headerLocation: "left",

  imgsrc: "/images/ReferAFriend.svg",
  imgalt: "Refer A Friend",
  imgcols: 6,
  imgheight:"160px", // ToDO: need to size this up based on screen view

  buttonText:"Refer a friend",
  buttonColor:"success",

  showNameField: true,
  messageBody: (<ReferralSection accessFrom="student"
    buttonText="Refer A Friend" buttonColor="success"
    bizinfo= {{
      logo: process.env.REACT_APP_LOGO,
      bizname: process.env.REACT_APP_NAME
    }}
    message="Please join SparkSIP at https://sparksip.org."
    subscribedMessage=<ThanksForReferral/>
    />)
};

export default StudentReferral;
