import React from "react";
import PricingSection from "./../../components/PricingSection";
import { requireAuth } from "./../../util/auth.js";
import AppConfigs from './../../configs';


// pricing plans for production testing
// TO BE WIRED IN SPECIFIC PLACE AND NOT FOR EVERYONE ELSE
const itemsForStudents_ProdTest = [
  {
    id: "student_allin_murali",
    name: "(PROD TEST) All In Access",
    price: "1",
    discount_percentage: "20",
    discount_deadline: "2021-04-03T00:00:00.000Z",
    description: "PRODUCTION TESTING OFFER. ALL PURCHASES HERE WILL BE DISALLOWED.",
    num_projects: 100,
    perks: [
      "Browse all sponsored projects.",
      "Apply to unlimited projects.",
      "Apply for local projects",
      "Apply for remote projects",
      "Admission to Application Preparation Group online workshop",
    ],
  }
];

function ProdTestPricingPage(props) {

  return AppConfigs.flags.purchase_prod_test
      ? (<PricingSection
          bg="white"
          textColor="dark"
          size="md"
          bgImage=""
          bgImageOpacity={1}
          title="Pricing"
          subtitle="Choose the plan that suits your interest."
          plans={itemsForStudents_ProdTest}
          onNow={AppConfigs.flags.enable_purchase}
        />)
      : (<p>Thanks for your Interest.</p>)
      ;
}

export default requireAuth(ProdTestPricingPage);
// export default ProdTestPricingPage;
