import GoogleSheet from './../../providers/googlesheet';
import {StudentReferralDbObject} from "./../../util/db";


class Referral {
  constructor( data) {
    this.source = "Sparksip21"; // coming from this app
    this.for_year= process.env.REACT_APP_SPARKSIP_PROJECT_YEAR ;
    this.emailFrom = data.emailFrom;
    this.emailTo = data.emailTo;
    this.message = data.message;
    this.accessFrom = data.accessFrom;
  }

  print() {
    console.log("\n Referral");
    console.log( `Source: ${this.source}`);
    console.log( `For Year: ${this.for_year}`);
    console.log( `Email From: ${this.emailFrom}`);
    console.log( `Email To: ${this.emailTo}`);
    console.log( `Message: ${this.message}`);
    console.log( `Access From: ${this.accessFrom}`);
  }

  asArray() {
    return [
      this.source,
      this.for_year,

      this.emailFrom,
      this.emailTo,
      this.accessFrom,

      this.message,
    ]
  }
}


function addReferral( referral) {

  // console.log( `About to submit referral for a business. ${JSON.stringify(referral)}`);

  // For now we record the data in both Google Sheet and Firestore

  // 1. Submit to GoogleSheet
  // console.log( process.env.REACT_APP_REFERRAL_IN_GOOGLE_SHEET);
  if ( process.env.REACT_APP_REFERRAL_IN_GOOGLE_SHEET === 'true') {
    GoogleSheet
      .submit(
        process.env.REACT_APP_SHEETS_ENDPOINT,
        process.env.REACT_APP_REFERRAL_TAB_ID,
        referral.asArray());
  }

  // 2. add to the Firesetore database
  return StudentReferralDbObject.CreateObject( referral);
}

const ReferralService = {
  addReferral: addReferral
};

export default ReferralService;
export { Referral};
