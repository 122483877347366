import React from "react";
import HeroSection from "./../components/HeroSection";
import FeaturesSection from "./../components/FeaturesSection";
import ClientsSection from "./../components/ClientsSection";
import TestimonialsSection from "./../components/TestimonialsSection";
import NewsletterSection from "./../my/components/NewsletterSection";
import AppConfigs from '../configs';

function IndexPage(props) {
  return (
    <>
      <HeroSection
        bg="primary"
        textColor="success"
        size="lg"
        bgImage={process.env.REACT_APP_HERO_IMAGE} // "/images/hero-image-sparksip.jpg"
        bgImageOpacity={1}
        title="SPARK SUMMER INTERNSHIP PROGRAM"
        subtitle="Connect high school students with industry experts, educators and mentors."
/*
        buttonText="Get Started"
        buttonColor="success"
        buttonPath="/pricing"
        */
      />
      <FeaturesSection
        bg="light-cyan"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Coming Soon"
        titlesize="4"
        subtitle="We are working on 2022 internships. Thanks for your interest!"
      />
      <ClientsSection
        bg="light"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="These Businesses sponsored internships"
        subtitle=""
      />
      <TestimonialsSection
        bg="celeste"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Here's what people are saying"
        subtitle=""
        testimonials={AppConfigs.testimonials}
      />
      <NewsletterSection
        bg="light"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Stay in the know"
        subtitle="Receive updates on our summer internship programs"
        buttonText="Subscribe"
        buttonColor="success"
        accessFrom="homepage"
        inputPlaceholder="Enter your email"
        subscribedMessage="You are now subscribed!"
      />
    </>
  );
}

/*
import CtaSection from "./../components/CtaSection";
<CtaSection
  bg="primary"
  textColor="light"
  size="sm"
  bgImage=""
  bgImageOpacity={1}
  title="Ready to get started?"
  subtitle=""
  buttonText="Get Started"
  buttonColor="light"
  buttonPath="/pricing"
/>
*/

export default IndexPage;
