import React from "react";
import HeroSection2 from "./../../components/HeroSection2";
import ProjectsSection from "./../components/ProjectsSection";
import { useRouter } from "./../../util/router.js";
import AppConfigs from '../../configs';
import ProjectsList from './../cache/projects';

function ProjectsPage(props) {
  const router = useRouter();
  const projectsToShow = (router.query.year && (router.query.year !== 'ALL'))
    ? ProjectsList.filter( p => (p.for_year === router.query.year))
    : ProjectsList
    ;

  return (
    <>
  {/* ToDo: Include filters and slicers */ }
      <ProjectsSection
        bg="bisque"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Sponsored Projects"
        projects={projectsToShow}
      />
    </>
  );
}

export default ProjectsPage;
