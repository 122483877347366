import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./SectionHeader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";
import { useAuth } from "./../util/auth.js";
import "./PricingSection.scss";


function DiscountedPricingSection( {item }) {

  const currentDate = new Date();
  const discount_date = new Date( item.discount_deadline ? item.discount_deadline : '2021-12-31');
  const shouldDiscount =  item.discount_percentage && (item.discount_percentage > 0) && (currentDate <= discount_date);
  const discount_perc = (shouldDiscount ? (1.0 - parseInt( item.discount_percentage)/100) : 1)
  const discounted_price = parseInt( item.price) * discount_perc ;

  const offset = discount_date.getTimezoneOffset()
  const adjustedDate = new Date(discount_date.getTime() - (offset*60*1000))
  const dateToShow = adjustedDate.toISOString().split('T')[0];

  return (
    <div className="PricingSection__pricing_header">
      <h2 className="font-weight-bold mb-2">
        ${discounted_price}
        <small className="PricingSection__period"> / year</small>
      </h2>
      {shouldDiscount &&
        (<p>Apply before <span className="PricingSection__discount">
        {discount_date.toDateString()} 11PM Pacific Time</span>
        <br/>and receive this&nbsp;
        <span className="PricingSection__discount">{item.discount_percentage}%
          discount (already applied).</span>
        </p>)
        }
      {item.description && (
        <Card.Text className="mb-2">{item.description}</Card.Text>
      )}
    </div>
    );
}

const PricingCard = ( {item, user, cardClassName, planIsActive, planMatch}) => (
  <Card className={"w-100 " + cardClassName}>
    <Card.Body className="d-flex flex-column p-3">
      <h5 className="font-weight-bold mb-2">{item.name}</h5>

      <DiscountedPricingSection item={item}/>

      {item.perks && (
        <Card.Text as="span" className="mt-2 mb-2 PricingSection__pricing_perks">
          <ul className="list-unstyled">
            {item.perks.map((perk, index) => (
              <li className="py-1" key={index}>
                <i className="fas fa-check text-success mr-3" />
                {perk}
              </li>
            ))}
          </ul>
        </Card.Text>
      )}

      {planIsActive
        ? (planMatch ? <h3>You Already Paid. Thanks! </h3> : "")
        :
        <LinkContainer
          to={
            user
              ? `/purchase/${item.id}`
              : `/auth/signup?next=/purchase/${item.id}`
          }
        >
          <Button
            variant="secondary"
            size="lg"
            block={true}
            className="mt-auto"
          >
            Buy Now
          </Button>
        </LinkContainer>
      }
    </Card.Body>
  </Card>
);

function PricingSection(props) {
  const auth = useAuth();

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >

      <Container>
        <SectionHeader
          title={props.title || ' '}
          subtitle={auth.user.planIsActive ? "Thanks for Paying your fees" : props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        />
        <Row className="justify-content-center">
          {props.plans.map((item, index) => (
            <Col
              md={12}
              lg={5}
              key={index}
            >
              <PricingCard user={auth.user} item={item}
                cardClassName={ auth.user.planIsActive && (auth.user.planId !== item.id)
                  ? 'PricingSection__inactive_price'
                  : 'PricingSection__active_price'}
                planIsActive={auth.user.planIsActive}
                planMatch={(auth.user.planId === item.id)}
                />
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  );
}

export default PricingSection;
