import React from "react";
import Section from "./../../components/Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./../../components/SectionHeader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { LinkContainer } from "react-router-bootstrap";
import Button from "react-bootstrap/Button";
import "./ProjectsSection.scss";
import AppConfigs from './../../configs';

function isPastYears(year) {
  return (year !== process.env.REACT_APP_SPARKSIP_PROJECT_YEAR);
}

const ShowFilters = ( {projects}) => {

  // get unique years and show only those
  const uniqueItems  = projects.map( p => p.for_year).sort();
  let uniqueYears = ["ALL", ...new Set(uniqueItems)];

  return (
    <Row className="justify-content-right">
      <Col xs={2}><h5>Filter by Year</h5></Col>
      {uniqueYears.map( (year, index) => (
        <Col xs={2} key={index}>
          <LinkContainer to={"/projects/?year=" + year}>
            <Button variant="info" size="sm">
              {year}
            </Button>
          </LinkContainer>
        </Col>
      ))}
    </Row>
  // pull out only the relevant years and show filter by these.
  );
}

const ProjectHeaderSection = ( {project, className }) => (
  <Card.Header className={className}>
    <h6 className="font-weight-bold mb-0 mt-2">
      { isPastYears(project.for_year) ? `(${project.for_year}) `: ''}
      {project.project_title}
    </h6>
    <Row>
      <Col xs={6}>
        <small>{ AppConfigs.flags.show_site_url && project.siteurl
        ? (<a href={project.siteurl} target="_blank">{project.organization}</a>)
        : project.organization
        }</small>
      </Col>
      <Col xs={6}><small>{project.location ? 'at: ' + project.location: ''}</small></Col>
    </Row>
  </Card.Header>
);

const ProjectFooterSection = ( {project, className}) => (
  <Card.Footer className={className}>
  {/*
    these have badges
      this.has_remote = data.has_remote;
      this.has_research = data.has_research;
      this.has_stipend = data.has_stipend ? data.has_stipend : 'No';

      ToDo: Build prettier badges
      */}
    <Row>
      <Col xs={4}><small>{project.has_remote !== "No"? "Remote: " + project.has_remote : ""}</small></Col>
      <Col xs={4}><small>{project.has_stipend === "Yes"? "$ stipend": ''}</small></Col>
      <Col xs={4}><small>{project.has_research !== "No"? "Has Research": ''}</small></Col>
    </Row>
    <Row>
      <Col xs={12} sm={8}><small>{project.numweeks? project.numweeks + " weeks": ""}</small>
        <small>{project.hours_per_week? " at " + project.hours_per_week + " hours per week" : ""}</small></Col>
    </Row>
  </Card.Footer>
);

function ProjectsSection(props) {

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        />

        <Row className="justify-content-left mb-3">
          <Col xs={12} md={4} className="offset-md-8">
            <LinkContainer to="/business/sponsor">
              <Button variant="success" size="md">
                Sponsor Projects
              </Button>
            </LinkContainer>
          </Col>
        </Row>

        {/* <ShowFilters projects={props.projects} /> */}

        <Row className="justify-content-center">
          {props.projects.map((item, index) => (
            <Col
              xs={12}
              md={4}
              lg={4}
              className="py-4 d-flex align-items-stretch"
              key={index}
            >
              <ShowProjectSummary project={item}/>
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  );
}


const ShowProjectSummary = ( { project} ) => (

  <Card className={ isPastYears(project.for_year) ? 'ProjectsSection__past_project': ''}>
  {/*
    ToDo: show the prior projects in grayed out mode
    */}
    <ProjectHeaderSection project={project} className="ProjectsSection__title" />
    <Card.Body className="d-flex flex-column align-items-center">
      <Card.Text className="mt-4 ProjectsSection__description">
        {project.description}
      </Card.Text>
    </Card.Body>

    <LinkContainer to={"/project/" + project.id}>
      <Button variant="secondary" size="md">
        Learn More
      </Button>
    </LinkContainer>

    <ProjectFooterSection project={project} className="ProjectsSection__footer"/>
    {/*
      ToDo: show the action buttons: view details, select, apply
      */}
  </Card>
);


function ProjectDetailSection(props) {

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <Row className="justify-content-center">
          <Col xs={3} className="offset-xs-3">
            { props.detail === true &&
              <LinkContainer to={"/projects"}>
                <Button variant="secondary" size="md">
                  {"<- Back to all Projects"}
                </Button>
              </LinkContainer>
            }
          </Col>
          <Col xs={5} className="justify-content-left">
          <SectionHeader
            title={props.title}
            subtitle={props.subtitle}
            size={2}
            spaced={true}
            className="text-center"
          />
          </Col>
        </Row>
        <Row className="justify-content-center">
          {props.projects.map((item, index) => (
            <Col
              xs={12}
              md={8}
              lg={8}
              className="py-2 d-flex align-items-stretch"
              key={index}
            >
              <ShowProjectDetail project={item}/>
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  );
}

{/*
  these go into the details Section
    this.siteurl = data.siteurl;
    this.numweeks = data.numweeks;
    this.hours_per_weeek = data.hours_per_weeek;

    this.qualifications = data.qualifications;

    this.research = data.research;
*/}

const ShowProjectDetail = ( { project} ) => (

  <Card className={ isPastYears(project.for_year) ? 'ProjectsSection__past_project': ''}>
  {/*
    ToDo: show the prior projects in grayed out mode
    */}

    <ProjectHeaderSection project={project} className="ProjectsSection__title" />

    <Card.Body className="d-flex flex-column align-items-center p-4">
      <Card.Text className="mt-4 ProjectsSection__description_detail">
        <b>Description</b>
        <br/>{project.description}
        <br/>{ project.has_research !== 'No' &&
          (<><br/><b>Research</b><br/>{project.research}<br/></>)
        }
        <br/><b>Qualifications</b>
        <br/>{project.qualifications}
      </Card.Text>
    </Card.Body>
    <ProjectFooterSection project={project}/>

    {/*
      ToDo: show the action buttons: view details, select, apply
      */}
  </Card>
);


export default ProjectsSection;
export { ProjectDetailSection };
