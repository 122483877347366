import React, { useState } from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./SectionHeader";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";

import RenderComponent from './../my/components/my_components';

import "./FaqSection.scss";

function FaqSection(props) {
  // Object to store expanded state for all items
  const [expanded, setExpanded] = useState({});
  // Set an item's expanded state
  const setExpandedItem = (index, isExpanded) => {
    setExpanded({
      ...expanded,
      [index]: isExpanded,
    });
  };

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        />

        {props.faq_items.map((item, index) => (
          <article
            className="FaqSection__faq-item py-4"
            id={item.id}
            key={index}
          >
            <h4
              onClick={() => {
                setExpandedItem(index, !expanded[index]);
              }}>
              <span className="text-primary mr-3">
                <i
                  className={
                    "fas" +
                    (expanded[index] ? " fa-minus" : "") +
                    (!expanded[index] ? " fa-plus" : "")
                  }
                />
              </span>
              {index+1} : {item.question}
            </h4>

            {expanded[index] &&
              <div className="mt-3">
                {Array.isArray(item.answer)
                  ? item.answer.map( (line, index2) => (
                    <p key={index2}>{line}</p>
                    ))
                  : item.answer
                }
                {item.image &&
                  <img src={item.image} alt={item.altForImage} width={item.image_width}/>
                }
                {item.component_id &&
                  <RenderComponent component_id={item.component_id} />
                }
                {item.link_id &&
                  <LinkContainer to={item.link_id}>
                    <Button variant="success" size="md">
                      {item.link_name}
                    </Button>
                  </LinkContainer>
                }
              </div>}
          </article>
        ))}
      </Container>
    </Section>
  );
}

export default FaqSection;
