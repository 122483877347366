import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import FormField from "./../../components/FormField";
import Button from "react-bootstrap/Button";
import ReferralService, {Referral} from "./../util/referral.js";
import { useForm } from "react-hook-form";

function ZReferralForm(props) {
  const [subscribed, setSubscribed] = useState(false);
  const { handleSubmit, register, errors } = useForm();

  const onSubmit = ({ emailFrom, emailTo, message,  }) => {

    const referral = new Referral( {emailFrom, emailTo, message, accessFrom: props.accessFrom })

    // add a referral based on user supplied information.
    ReferralService.addReferral( referral)
      .then( (result) => {
        setSubscribed(true);
        // Parent component can optionally
        // find out when subscribed.
        props.onSubscribed && props.onSubscribed();
        // console.log( `ReferralForm: Result from submission: ${JSON.stringify(result)}`);
      })
      .catch( err => {

        console.log( `ReferralForm: Unable to submit referral. We will look into the failure. Thanks.`);
        console.log( err);
      })
      ;
  };

  return (
    <>
      {subscribed === true && <div>{props.subscribedMessage}</div>}

      {(subscribed === false && ['business', 'student'].includes(props.accessFrom)) &&
        (
        <>
        <div>{props.inviteMessage}</div>
        {props.bizinfo.logo &&
          (<img src={props.bizinfo.logo} alt={props.bizinfo.name} max-width="100%"/>)
        }
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Row>
            <Form.Group as={Col} xs={12} sm={10}
            controlId="formEmailFrom"
            >
              <FormField
                size="md"
                label="Your Email to use"
                name="emailFrom"
                type="email"
                placeholder="Email From"
                error={errors.emailFrom}
                inputRef={register({
                  required: "Please enter an email address",
                })}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} xs={12} sm={10}
            controlId="formEmailTo"
            >
              <FormField
                size="md"
                label="Your Friend's Email"
                name="emailTo"
                type="email"
                placeholder="Friend's Email"
                error={errors.emailTo}
                inputRef={register({
                  required: "Please enter an email address",
                })}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} xs={12} sm={10}
            controlId="formMessage"
            >
              <FormField
                size="md"
                label="Your Message"
                name="message"
                type="textarea"
                rows={2}
                placeholder={props.message}
                defaultValue={props.message}
                error={errors.message}
                inputRef={register({
                  required: "Please enter your message",
                })}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Col xs="auto">
              <Button variant={props.buttonColor} size="md" type="submit">
                {props.buttonText}
              </Button>
            </Col>
          </Form.Row>
        </Form>
        </>
      )}
    </>
  );
}

export default ZReferralForm;
