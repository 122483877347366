import React from "react";

const BusinessHeaderForCommunity = () => ( <>
  <p>Congratulations on creating innovative products and solutions for the world!
  Your companies and teams are an inspiration for emerging workforce.
  </p><p>
  We at <a href="/">SparkSIP</a>, as a non-profit, have connected high school students to
   summer internships for the past five years. We have received interests
   from 200 students already within a week.
   They have interests spanning Business, Computer Science, Medicine, Psychology,
   Law, etc.
  </p><p>
  We request businesses like yours to sponsor projects.
  Most projects are offered for learning purposes to students;
  some sponsors may offer up to a maximum of $500 stipend in the US.
  </p>
</>);

const SponsorIntroduction = () => (
  <>
  <p>
  Please answer a few questions to submit an internship project.
  Now we are also opening up remote internships for you to reach distributed students.
  </p><p>
  <a href="/projects">Projects</a> has a listing of current and past projects.
  <br/>
  For further information please see the <a href="/faq">FAQ</a> section.
  </p>
  </>
);

const ReviewIntroduction = () => (
  <>
  <p>Spark SIP has posted a batch of applicants for your sponsorsed projects.
  Each applicant has submitted their resume, letter of recommendations,
  and a personal statement. Kindly please review the candidates and set up a
  direct interview with applicants you prefer to speak with.
  </p>
  <p>
  For further information please see the <a href="/faq">FAQ</a> section.
  </p>
  </>
);

const SelectionIntroduction = () => (
  <>
  <p>After the interviews with the applicants, please submit the list
  of applicants you want to offer an internship in the summer.
  Please recognize that an applicant may have applied for multiple projects.
  During the selection phase, both the business and applicant submit
  their preference list to be matched. In most cases, the match is very quick.
  </p>
  <p>
  For further information please see the <a href="/faq">FAQ</a> section.
  </p>
  </>
);

const CompletionIntroduction = () => (
  <>
  <p>Voila! You are completing the internship. Thanks a LOT! We appreciate the
  mentorship and coaching you had provided for a talented student. They will
  remember this experience for their life, especially given that this internship
  potentially shaped their future pursuits. Please fill in a short survey.
  </p>
  <p>
  For further information please see the <a href="/faq">FAQ</a> section.
  </p>
  </>
);


const BusinessActionsDetail = {

  'community_header': {
    // title: "Sponsor Project", // title will be customized inside use of community_header

    bg: "bisque",
    textColor: "dark",

    imgsrc:"/images/blog/Sparksip-interests-feb16-2021.png",
    imgalt:"Student Interests",
    imgcols:4,
    imgheight:"300px",

    buttonText:"View select Projects",
    buttonColor:"secondary",
    buttonPath:"/projects",

    messageBody: (<BusinessHeaderForCommunity />)
  },

  'sponsor_project': {
    title: "Sponsor Project",

    bg: "celeste",
    textColor: "dark",

    imgsrc:"/images/stand_front_star1.svg",
    imgalt:"Sponsor Project",
    imgcols:4,
    imgheight:"300px",

    buttonText:"Sponsor Project",
    buttonColor:"success",
    buttonPath:"/business/sponsor",

    messageBody: (<SponsorIntroduction />)
  },

  'review_candidates': {
    title: "Review Candidates",

    imgsrc: "/images/desk_laptop1.svg",
    imgalt:"Review Candidates",
    imgcols:4,
    imgheight:"300px",

    buttonText: "Review Candidates",
    buttonColor: "success",
    // turn on buttonPath when we are ready
    // buttonPath:"/business/review",

    messageBody: (<ReviewIntroduction />)
  },

  'select_candidates': {
    title: "Select Candidates",

    imgsrc: "/images/stand_back_point2.svg",
    imgalt: "Select Candidates",
    imgcols:4,
    imgheight:"300px",

    buttonText: "Select Candidates",
    buttonColor: "success",
    // turn on buttonPath when we are ready
    // buttonPath:"/business/select",

    messageBody: (<SelectionIntroduction />)
  },

  'complete_internship': {

    title: "Complete Internship",

    imgsrc: "/images/dance_2.svg",
    imgalt: "Complete Internship",
    imgcols:4,
    imgheight:"300px",

    buttonText: "Complete Internship",
    buttonColor: "success",
    // turn on buttonPath when we are ready
    // buttonPath:"/business/complete",

    messageBody: (<CompletionIntroduction />)
  }
};

export default BusinessActionsDetail;
