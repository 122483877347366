import React from "react";

const ViewProjects = () => (
  <>
  <p>
  As internship sponsors provide their information,
  Spark SIP will be putting them on the website.
  We expect the student applicants to continually browse this information
  to check in on opportunities that may be of interest to them.
  </p>
  <p>
  We will make every attempt to ensure that reasonable details are provided.
  Given that we work with many small businesses and in early stages,
  the project scope may change.
  That is often the nature of a fast moving business environment.
  We also expect the students to review internship opportunities and
  decide on which ones to apply for.
  </p>
  <p>
  For further information please see the <a href="/faq">FAQ</a> section.
  </p>
  </>
);

const StudentProjects = {
  id: "projects",
  name: "Projects",
  status: "active",

  title: "View Projects",

  bg: "bisque",
  textColor: "dark",
  size: "md",

  imgsrc:"/images/stand_front_star1.svg",
  imgalt:"View Projects",
  imgcols: 2,
  imgheight:"300px",

  buttonText:"View Projects",
  buttonColor:"secondary",
  buttonPath:"/projects?year=2021",

  messageBody: (<ViewProjects />)
};

export default StudentProjects;
