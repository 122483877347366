import React from "react";
import { WorkshopAlertBanner } from "./AlertBanners";
import PricingSection from "./../../components/PricingSection";
import AppConfigs from './../../configs';

const IsShowWorkshopBanner = () => {
  const currentDate = new Date();
  const workshop_completed_date = new Date( '2021-04-03'); // ToDo: DO NOT hardcode this.
  return (currentDate <= workshop_completed_date);
}


const StudentPayment = {
  id: "pay",
  name: "Pay Fees",
  status: "active",
  title: "Pay Application Fees",

  bg: "bisque",
  textColor: "dark",
  size: "md",

  imgcols:0,
  imgsrc: "/images/payment_with_card.png",
  imgalt:"Pay Application Fees",
  imgheight:"240px",

  buttonText: "Pay Fees",
  buttonColor: "success",
  // turn on buttonPath: when we are ready

  messageBody: (
    <>
      {IsShowWorkshopBanner() ? <WorkshopAlertBanner /> : "" }
      <PricingSection
  //        title="Pricing for Students"
          subtitle="Choose the plan that suits your interest."
          plans={AppConfigs.itemsForStudents}
          onNow={AppConfigs.flags.enable_purchase}
      />
    </>
    )
};

export default StudentPayment;
