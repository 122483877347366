// student.js - landing page for the student users

import React from "react";
import BusinessDashboardSection from './../components/BusinessDashboardSection';
// import { requireAuth } from "./../util/auth.js";

const BusinessPage = (props) => ( <BusinessDashboardSection {...props} />);

// later enable auth model
// export default requireAuth(BusinessPage);

export default BusinessPage;
