import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";

function FormField(props) {
  const { error, type, inputRef, ...inputProps } = props;

  return (
    <>
      {props.label && <Form.Label>{props.label}</Form.Label>}

      <Form.Control
        as={type === "textarea" ? "textarea" : "input"}
        type={type === "textarea" ? undefined : type}
        isInvalid={error ? true : undefined}
        ref={inputRef}
        {...inputProps}
      />

      {error && (
        <Form.Control.Feedback type="invalid" className="text-left">
          {error.message}
        </Form.Control.Feedback>
      )}
    </>
  );
}

function FormSelect(props) {
  const { error, inputRef, select_options, ...inputProps } = props;

  return (
    <>
      {props.label && <Form.Label>{props.label}</Form.Label>}

      <Form.Control as="select"
        ref={inputRef}
        {...inputProps}
        >{select_options.map( (item, index) => (
          <option key={index}>{item}</option>
        ))}
      </Form.Control>

      {error && (
        <Form.Control.Feedback type="invalid" className="text-left">
          {error.message}
        </Form.Control.Feedback>
      )}
    </>
  );
}

function FormReferralFrom( props) {
  const { label, name, error, inputRef } = props;

  return (
    <Form.Group as={Col} xs={props.xs_cols || 12} sm={props.sm_cols || 12}
      controlId="formReferralFrom">
      <FormSelect
        size={props.size || "lg"}
        label={props.label}
        name={props.name}
        type="select"
        placeholder="Direct Email"
        select_options={[ "Direct Email", "Friends & Family", "Google Search", "Linked In",
          "Facebook", "Twitter", "Past User", "Other"]}
        error={error}
        inputRef={inputRef}
      />
    </Form.Group>
  );
}

export default FormField;
export { FormSelect, FormReferralFrom};
