import React from "react";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { LinkContainer } from "react-router-bootstrap";
import useSteps from "./../util/useSteps";

function PagingNavContainer(props) {

  // 1. State information for the step by step sequencing
  const { index, step, navigation } = useSteps({ initialStep: 0, steps: props.navitems });
  const { previous, next } = navigation;

  // 2. Set up properites for invoking the steps
  //  validation logic is inside the form/data/display section,
  //  so navigation control is sent there

  const fnNext = ( event) => {
    event.preventDefault();

    // console.log( `Step ${index}: has form data`); console.log( formData);

    if (index === (props.navitems .length - 1)) {
      // ALL DONE. Now call the upstream onComplete

      // console.log( `Sending to onComplete function for posting ... `, props.onComplete);
      if ( props.onComplete) { props.onComplete(); }
    } else {
      next(); // move to the next step
    }
  }

  // 3. find what step we are at and accordingly set up the previous and next
  // ToDo: optimize this computation in the future
  let section_index = 0;
  props.navitems.forEach( (navItem, i) => {
    if ( navItem.id === props.section_id) {
      section_index = i;
    }
  });

  const navControls = {

    fnPrevious: (section_index === 0) ? props.fnPrevious : previous,
    prevLabel: (section_index === 0) ? props.prevLabel : "Back",
    fnNext: (section_index < props.navitems.length - 1) ? fnNext : null,
    nextLabel: "Next"
  };

//        &nbsp;
//         <a className="btn btn-info mr-auto" href="/students/prepare">
//          {navControls.prevLabel}: Prepare</a>
//        <a className="btn btn-primary ml-auto" href="/students/refer">{navControls.nextLabel}</a>
/*
disbaling the prior link does not work !!

<LinkContainer className="btn btn-md btn-info disabled mr-auto ml-5 mb-3" to={props.navitems[0].url}>
    <>&nbsp;</>
  </LinkContainer>
*/
  return (
    <>
      <Nav variant="pills">
        {navControls.prevLabel
          ? <LinkContainer className="btn btn-info btn-md mr-auto ml-5 mb-3"
              to={props.navitems[section_index - 1].url}>
            <Nav.Link eventKey="previous">{navControls.prevLabel}</Nav.Link>
          </LinkContainer>
          : (<>&nbsp;</>)
        }

        {props.navitems.filter( x => x.status === "active")
          .map( (navItem, i) => (
          <Nav.Item key={i}>
            <LinkContainer to={navItem.url}>
              <Nav.Link eventKey={navItem.id}>{navItem.name}</Nav.Link>
            </LinkContainer>
          </Nav.Item>
        ))}

        {navControls.fnNext &&
          <LinkContainer className="btn btn-success btn-lg ml-auto mr-5 mb-2"
            to={props.navitems[section_index + 1].url}>
            <Nav.Link eventKey="next">{navControls.nextLabel}</Nav.Link>
          </LinkContainer>
        }

      </Nav>
    </>
  );
}

export default PagingNavContainer;
