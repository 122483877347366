import React from "react";
import StudentProfileSection from "./StudentProfileSection";
import AppConfigs from './../../configs';

const ProfileUpdated = () => ( <h2>Your profile is updated </h2>);

const StudentProfile = {
  id: "profile",
  name: "Profile",
  status: "active",

  title: "Student Profile",
  headerLocation: "right",

  bg: "bisque",
  textColor: "dark",
  size: "md",

  imgcols: 0, // DO NOT SHOW the IMAGE SECTION
  imgsrc: "/images/sit_read.svg",
  imgalt: "Update Profile",
  imgheight:"240px",

  showNameField: true,
  messageBody: (   <StudentProfileSection
      buttonText="Update Profile"
      buttonColor="success"
      accessFrom="student"
      inputPlaceholder="Enter your email"
      subscribedMessage=<ProfileUpdated />
    />)
};

export default StudentProfile;
