import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import AppConfigs from '../../configs';

import "./TimelineSection.scss";

/*
// Show the old style TIMELINE image for the process flow
const ShowTimeline1 = () => (
  <Col
    xs={12}
    lg={4}
    style={{
      display: "flex",
      alignItems: "stretch",
      justifyContent: "center",
      boxShadow: "1px 1px 0 0 #efefef",
      backgroundColor:"var(--celeste)"
    }}
  >
    <img src="/images/timeline-sparksip.png" width="360px"/>
  </Col>
);
*/

const TimelineSectionInternal = ( {timeline_actions} ) => (
  <Col
    xs={12}
    lg={4}
    style={{
      display: "flex",
      alignItems: "stretch",
      justifyContent: "center",
      boxShadow: "1px 1px 0 0 #efefef",
      backgroundColor:"var(--celeste)"
    }}
  >
    <div className="TimelineSection">
      <h4>SparkSIP Timeline of Events</h4>
      <div className="TimelineSection__events">
      {timeline_actions.map( (item, index) => (
        <Row key={index} className={item.status==="active" ? "TimelineSection__active" : ""}>
          <Col xs={4} lg={4} className="text-right"><h5>{item.month}</h5>
          </Col>
          <Col xs={8} lg={8} className="TimelineSection__actions text-left">
            <h5>{item.title}</h5>
            {Array.isArray(item.detail)
              ? item.detail.map( (line, i2) => ( <div key={i2}><span>{line}</span><br/></div>))
              : (<p>{item.detail}</p>)
              }
          </Col>
        </Row>
      ))}
      </div>
    </div>
  </Col>
);

const TimelineSection = ( {timeline_actions} ) => {
  const actions = (timeline_actions) ? timeline_actions : AppConfigs.actions_for_timeline;

  return (<TimelineSectionInternal timeline_actions={actions} />);
};

export default TimelineSection;
