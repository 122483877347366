import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./AlertBanners.scss";

const DeadlineAlertBanner_preApr3 = ( props ) => (
  <Container style={{ "background-color": "var(--lite-yellow)"}}>
    <Row>
      <Col xs={6} className="offset-2">
      <p>
        Deadline for application:&nbsp;
          <strong style={{"font-size": "1.5em", "color": "red"}}>{process.env.REACT_APP_DEADLINE}</strong>.
        <br/>
        <a href="/students/pay">Early bird offer to pay application fees</a> end on &nbsp;
          <strong style={{"font-size": "1.2em", "color": "green"}}>Apr 2nd 2021</strong>.
      </p>
      </Col>
      <Col xs={4} style={{"padding-top": "10px"}}>
        Online Workshop for <a href="/students/pay">All In Access</a> users
        <br/> on <strong>April 3rd, 2021 (Sat) at 3-4pm Pacific Time</strong>
      </Col>
    </Row>
  </Container>
);


const DeadlineAlertBanner = ( props ) => (
  <Container style={{ "backgroundColor": "var(--lite-yellow)"}}>
    <Row>
      <Col xs={6} className="offset-2">
      <p>
        Deadline for application:&nbsp;
          <strong style={{"fontSize": "1.5em", "color": "red"}}>{process.env.REACT_APP_DEADLINE}</strong>.
      </p>
      </Col>
    </Row>
  </Container>
);

const NewProjectsAlertBanner = ( props ) => (
  <Container style={{ "backgroundColor": "var(--jasmine)"}}>
    <Row>
      <Col xs={6} className="offset-2">
      <p>
        Please check out the <a href="/students/projects">projects</a>; several came in the past few days.
      </p>
      </Col>
    </Row>
  </Container>
);

const ApplicationClosedAlertBanner = ( props ) => (
  <Container className="AlertBanners__application_closed">
    <Row>
      <Col xs={8} className="offset-2">
      <p>
        Thank You for your interest in SparkSIP Summer Internships.
        <br/>
        Our deadline for application was: &nbsp;
          <strong style={{"fontSize": "1.5em", "color": "red"}}>{process.env.REACT_APP_DEADLINE}</strong>.
      </p>
      <p>
        We are closed for the 2021 Summer Internships. We will be back again in 2022.
        <br/>
        We thank our <b>Sponsors</b> and <b>Students</b> for making this another great year of internships.
      </p>
      <p>
        The next steps are: applicant matching and interviews from the sponsors.
        <br/>We will be in touch with the applicants and sponsors.
      </p>
      </Col>
    </Row>
  </Container>
);


const WorkshopAlertBanner = ( props ) => (
  <Container style={{ "background-color": "var(--jasmine)"}}>
    <Row>
      <Col className="offset-3">
      <h6>Online Workshop for the <strong>All In Access</strong> users</h6>
      <p>
      Hear from the SPARK SIP Founder Dr. Mani Vadari and CTO Murali Krishnan.
      <br/>Receive guidance to answering essays, questions and prompts.
      <br/>Insight about steps to creating an effective resume to highlight your achievements.
      <br/>Get tips focused on completing the personal essays to showcase your strengths.
      <br/>
      <br/>Date: Saturday, April 3rd, 2021 at 3:00pm-4:00pm PST
      </p>
      </Col>
    </Row>
  </Container>
);

export { DeadlineAlertBanner, NewProjectsAlertBanner, ApplicationClosedAlertBanner, WorkshopAlertBanner};
