import React from 'react';
import Container from "react-bootstrap/Container";
import { useAuth, requireAuth } from './../../util/auth.js';
import { useItemsByOwner } from './../../util/db.js';
import { Route, Link, useRouter } from "./../../util/router.js";
import ZReferralForm from "./ZReferralForm";

const ThanksForReferral = () => (
  <>
    <h2>Thanks for your referral!</h2>
    <p>Please refer your other friends too.
    <br/>
    <a className="btn btn-primary" href="./zreferral">Submit Another Referral</a>
    </p>
  </>
);

// let us directly get data from the "referrals" collection to show
function ZItemszReferralPage(props) {

  return (
    <Container>
      <ZReferralForm
          buttonText="ZITEMZ: Refer A Friend"
          buttonColor="success"
          bizinfo= {{
            logo: process.env.REACT_APP_LOGO,
            bizname: process.env.REACT_APP_NAME
          }}
          message="Please join SparkSIP at https://sparksip.org."
          subscribedMessage=<ThanksForReferral/>
          accessFrom="student"
        />
    </Container>
  );
}

export default ZItemszReferralPage;
