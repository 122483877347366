import React from "react";

const CompletionIntroduction = () => (
  <>
  <p>Voila! You are completing the internship. Thanks a LOT! We appreciate the
  mentorship and coaching you had provided for a talented student. They will
  remember this experience for their life, especially given that this internship
  potentially shaped their future pursuits. Please fill in a short survey.
  </p>
  <p>
  For further information please see the <a href="/faq">FAQ</a> section.
  </p>
  </>
);

const CompleteInternship = {
  id: "complete",
  name: "Complete",
  status: "not-ready",

  title: "Complete Internship",

  imgsrc: "/images/dance_2.svg",
  imgalt: "Complete Internship",
  imgcols:4,
  imgheight:"300px",

  buttonText: "Complete Internship",
  buttonColor: "success",
  // turn on buttonPath when we are ready
  // buttonPath:"/business/complete",

  messageBody: (<CompletionIntroduction />)
};

export default CompleteInternship;
