import GoogleSheet from './../../providers/googlesheet';
// import Project from './../objects/project';

function addProject( project) {

  console.log( `About to submit project for a business. \n${JSON.stringify(project)}`);

  return GoogleSheet
    .submit(
      process.env.REACT_APP_SHEETS_ENDPOINT,
      process.env.REACT_APP_PROJECTS_TAB_ID,
      project.asArray());
}

const Business = {
  addProject: addProject
};

export default Business;
