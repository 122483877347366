import React from "react";
import FaqSection from "./../components/FaqSection";
import AppConfigs from '../configs';

function FaqPage(props) {
  return (
    <>
      <FaqSection
        bg="bisque"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Students: Frequently Asked Questions"
        subtitle=""
        faq_items={AppConfigs.faq_student_items}
      />
      <FaqSection
        bg="celeste"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Business: Frequently Asked Questions"
        subtitle=""
        faq_items={AppConfigs.faq_business_items}
      />
    </>
  );
}

export default FaqPage;
