const stats_data = [
  {
    title: "Student Interns",
    stat: "143",
  },
  {
    title: "Sponsored Projects",
    stat: "75",
  },
  {
    title: "Yearly Student Applicants",
    stat: "80+",
  },
  {
    title: "Likes",
    stat: "903",
  },
];

export default stats_data;
