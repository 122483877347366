import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "./../util/router.js";
import "./Footer.scss";

import PACKAGE_INFO from "../../package.json";

// © 2015-2021 Sparksip.org | contact | terms of use | privacy policy

function Footer(props) {

  return (
    <Section
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      className="footer FooterComponent"
    >
      <Container>
        <Row>
          <Col xs={12} md={6}>
            <Link to="/">
              <div>
                <img
                  className="FooterComponent__logo"
                  src={props.logo}
                  alt="Logo"
                />
              </div>
            </Link>

            {props.description && (
              <p className="FooterComponent__description">
                {props.description}
              </p>
            )}

            {props.copyright && (
              <p className="FooterComponent__copyright">{props.copyright}
              &nbsp; <small>({PACKAGE_INFO.version})</small></p>
            )}
          </Col>
          <Col xs={12} md={6} lg={{ span: 6 }}>
            <div className="FooterComponent__menus">
              <Row>
                <Col xs={12} md={4} className="mt-3 mt-md-0">
                  <h5>For</h5>
                  <ul className="list-unstyled">
                    <li>
                      <Link to="/business">Business</Link>
                    </li>
                    <li>
                      <Link to="/students">Students</Link>
                    </li>
                    {/*
                    <li>
                      <Link to="/pricing">Pricing</Link>
                    </li>
                    */}
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://blog.sparksip.org/"
                      >
                        Blog
                      </a>
                    </li>
                    <li>
                      <Link to="/faq">FAQ</Link>
                    </li>
                  </ul>
                </Col>
                <Col xs={12} md={4} className="mt-3 mt-md-0">
                  <h5>Company</h5>
                  <ul className="list-unstyled">
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                    <li>
                      <Link to="/docs/20200309-SparkSIPTermsofUse.html"
                      target="_blank">Terms of Use</Link>
                    </li>
                    <li>
                      <Link to="/docs/20200309-SparkSIPPrivacyNotice.html"
                      target="_blank">Privacy Policy</Link>
                    </li>
                    {/* -

                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://blog.sparksip.org/terms-of-use/"
                      >
                        Terms of Use
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://blog.sparksip.org/privacy-policy/"
                      >
                        Privacy Policy
                      </a>
                    </li>
                    -- */}
                  </ul>
                </Col>
                <Col xs={12} md={4} className="mt-3 mt-md-0">
                  <h5>Social</h5>
                  <ul className="list-unstyled">

                    <li>
                      <a target="_blank"
                        rel="noopener noreferrer"
                        href="mailto:spark.sip@outlook.com">
                        <i className="fas fa-envelope">&nbsp;</i> Email SparkSIP
                      </a>
                    </li>

                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.facebook.com/SPARK-Summer-Internship-Program-957570364288439/"
                      >
                      <i className="fab fa-facebook-f">&nbsp;</i> Facebook
                      </a>
                    </li>

                  </ul>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  );
}

/* -- social site connections: twitter and instagram
  <li>
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://twitter.com/divjoy"
    >
      <img
        src="https://uploads.divjoy.com/icon-twitter.svg"
        alt="Twitter"
      />
      Twitter
    </a>
  </li>
  <li>
    <a
      target="_blank"
      rel="noopener noreferrer"
      href="https://instagram.com"
    >
      <img
        src="https://uploads.divjoy.com/icon-instagram.svg"
        alt="Instagram"
      />
      Instagram
    </a>
  </li>
*/
export default Footer;
