// hello_page.js - landing page for the specific community of business users
// /hello/:community_id

import React from "react";
import { useRouter } from "./../../util/router.js";
import NotFoundPage from "./../../pages/not-found";
import BusinessActionsDetail from './../components/BusinessActionsDetail';
import RenderMultipleSections, {ActionContainerSection} from './../components/ActionContainerSection';
import AppConfigs from './../../configs';

function HelloPage(props) {

  const router = useRouter();
  const community_id = router.query.community_id;
  const community = (community_id && community_id in  AppConfigs.map_of_communities)
    ? AppConfigs.map_of_communities[ community_id]
    : AppConfigs.map_of_communities[ 'default'];

  const sections = [
    BusinessActionsDetail.community_header,
    BusinessActionsDetail.sponsor_project
  ];

  return ( community
    ? (<RenderMultipleSections sections={sections}
        firstTitle={"Hello " + community.name + " Community"}
        {...props}/>)
    : (<NotFoundPage {...props} />)
  );
}

export default HelloPage;
