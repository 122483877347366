import React from "react";
import PricingSection from "./../components/PricingSection";
import AppConfigs from './../configs';
import { requireAuth } from "./../util/auth.js";

function PricingPage(props) {
  return (
    <PricingSection
      bg="white"
      textColor="dark"
      size="md"
      bgImage=""
      bgImageOpacity={1}
      title="Pricing"
      subtitle="Choose the plan that suits your interest."
      plans={AppConfigs.itemsForStudents}
      onNow={AppConfigs.flags.enable_purchase}
    />
  );
}

// old subtitle
// subtitle="Choose the plan that makes sense for you. All plans include a 14-day free trial."

export default requireAuth(PricingPage);
