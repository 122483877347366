
const feature_items = [
  {
//    status: "active",
    title: "For Students",
    body: [
      "Apply for exciting internships.",
      "Prepare personal statement and resume.",
//      "Learn to get recommendation letters.",
//      "Work on meaningful projects.",
      "Learn more; Develop skills.",
      "Gain real world experience.",
//    "Apply before Apr 2nd for early bird offer.",
      "All applications due by " + process.env.REACT_APP_DEADLINE + ".",
    ],
    image: "/images/students-group-2-sm.jpg",
    actionTitle: "Applications closed for 2021",
    actionStyle: "info",
    to: "/students"
  },
  {
    title: "For Business",
    body: [
      "Sponsor internship projects.",
//      "Connect with diverse talent.",
//      "Get verified student applicants.",
      "Engage talented High Schoolers.",
      "Coach emerging talent.",
      "Get Projects completed.",
    ],
    image: "/images/sponsor-group-2-sm.jpg",
    actionTitle: "Sponsoring closed for 2021",
    actionStyle: "info",
    to: "/business/sponsor"
  }
];

export default feature_items;
