
import ProjectsJSON from './projects_current';

function shuffleArray( inputArray) {
  // random shuffle to produce a decent distribution
  for(let i = (inputArray.length - 1); i > 0; i--){
    const j = Math.floor(Math.random() * i);
    const temp = inputArray[i];
    inputArray[i] = inputArray[j];
    inputArray[j] = temp;
  }

  return inputArray;
}

// filter for projects that we can show and
//  shuffle the array per load to keep it different
const ProjectsList = shuffleArray(ProjectsJSON.projects.filter( (item) => (item.source !== 'internal')));

export default ProjectsList;
