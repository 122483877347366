/*
  project.js - defines the "Project" object that tracks information about a project
*/

class Project {
  constructor( data) {
    this.source = "Sparksip21"; // coming from this app
    this.for_year= process.env.REACT_APP_SPARKSIP_PROJECT_YEAR ;
    this.name = data.name;
    this.email = data.email;

    this.organization = data.organization;
    this.siteurl = data.siteurl;

    this.numweeks = data.numweeks;
    this.hours_per_weeek = data.hours_per_weeek;

    this.location = data.location;
    this.has_remote = data.has_remote;

    this.project_title = data.project_title;
    this.description = data.description;
    this.qualifications = data.qualifications;

    this.has_research = data.has_research;
    this.research = data.research;
    this.has_stipend = data.has_stipend ? data.has_stipend : 'No';
  }

  asArray() {
    return [
      this.source,
      this.for_year,
      this.name,
      this.email,

      this.organization,
      this.siteurl,

      this.numweeks,
      this.hours_per_weeek,

      this.location,
      this.has_remote,

      this.project_title,
      this.description,
      this.qualifications,

      this.has_research,
      this.research,

      this.has_stipend
    ];
  }

}


export default Project;
