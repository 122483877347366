import React from "react";
import "./../styles/global.scss";
import NavbarCustom from "./../components/NavbarCustom";
import IndexPage from "./index";
import AboutPage from "./about";
import FaqPage from "./faq";
import PricingPage from "./pricing";
import ContactPage from "./contact";
import SettingsPage from "./settings";
import PurchasePage from "./purchase";
import AuthPage from "./auth";
import { Switch, Route, Router, Redirect } from "./../util/router.js";
import FirebaseActionPage from "./firebase-action.js";
import NotFoundPage from "./not-found.js";
import Footer from "./../components/Footer";
import "./../util/analytics.js";
import { AuthProvider } from "./../util/auth.js";

import BusinessPage  from './../my/pages/business';
import StudentPage from './../my/pages/students';
import { StudentPreparePage, StudentReferralPage,
    StudentPreregisterPage, StudentApplicationClosedPage }
  from './../my/pages/student_unauthenticated';

import SponsorPage from "./../my/pages/sponsor";
import SponsorThanksPage from "./../my/pages/sponsor_thanks";

import ProjectsPage from "./../my/pages/projects";
import ProjectDetailPage from './../my/pages/project_detail';

import SparksipDashboardPage from "../my/pages/dashboard";

import AppConfigs from '../configs';
import HelloPage from "./../my/pages/hello_page";
import ZItemszPage from "./../my/zitemz/z_items";

// My custom additions for SparkSIP ... since I created these pages offline
import 'font-awesome/css/font-awesome.min.css';

const APP_LOGO=process.env.REACT_APP_LOGO; // "/images/sparksip-logo.jpeg";

function App(props) {
  const appsDeadlineReached = AppConfigs.flags.appsDeadlineReached;

  return (
    <AuthProvider>
      <Router>
        <>
          <NavbarCustom
            bg="white"
            variant="light"
            expand="md"
            logo={APP_LOGO}
          />

          <Switch>
            <Route exact path="/" component={IndexPage} />

            <Route exact path="/about" component={AboutPage} />

            <Route exact path="/faq" component={FaqPage} />

            <Route exact path="/pricing" component={PricingPage} />

            <Route exact path="/contact" component={ContactPage} />

            <Route exact path="/dashboard" component={SparksipDashboardPage} />

            <Route exact path="/settings/:section" component={SettingsPage} />
            <Route exact path="/settings" component={SettingsPage} />

            <Route exact path="/business/sponsor" component={SponsorPage} />
            <Route exact path="/business/sponsor/thankyou" component={SponsorThanksPage} />
            <Route exact path="/business" component={BusinessPage} />

            <Route exact path="/refer" component={StudentReferralPage} />

            <Route exact path="/preregister" component={StudentPreregisterPage} />
            <Route exact path="/students"
              component={appsDeadlineReached
                ? StudentApplicationClosedPage
                : StudentPreparePage
              } />
              // following is the detailed student page
            <Route path="/students/:section_id"
              component={appsDeadlineReached
                ? StudentApplicationClosedPage
                : StudentPage
              } />
            <Route exact path="/purchase/:plan"
            component={appsDeadlineReached
              ? StudentApplicationClosedPage
              : PurchasePage
            } />

            <Route path="/hello/:community_id" component={HelloPage} />
            <Route path="/hello" component={HelloPage} />

            <Route exact path="/projects" component={ProjectsPage} />
            <Route path="/project/:project_id" component={ProjectDetailPage} />

            <Route exact path="/auth/:type" component={AuthPage} />

            <Route path="/zitemz" component={ZItemszPage} />

            <Route exact path="/blog" component={() => {
                 window.location.href = 'https://blog.sparksip.org';
                 return null;
            }}/>

            <Route
              exact
              path="/firebase-action"
              component={FirebaseActionPage}
            />

            <Route component={NotFoundPage} />
          </Switch>

          <Footer
            bg="white"
            textColor="dark"
            size="md"
            bgImage=""
            bgImageOpacity={1}
            description={process.env.REACT_APP_DESCRIPTION} // "SparkSIP connects talented high school students with engaging internships."
            copyright={process.env.REACT_APP_COPYRIGHT} // "© 2016-2021 SparkSIP"
            logo={APP_LOGO}
          />
        </>
      </Router>
    </AuthProvider>
  );
}

// removed to avoid navigation bar issues:
// <Route exact path="/preregister" component={StudentPreregisterPage} />
// we now have a way to handle this in the /students

export default App;
